import { Box, Button, FormControl, Grid, InputLabel, TextField, Typography, IconButton, InputAdornment,  } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import {ReactComponent as DamLogo} from '../assest/svg/DamLogo.svg';
// import {ReactComponent as DamText} from '../assest/svg/DamText.svg';
import {ReactComponent as PimcoreLogo} from '../assest/svg/pimcoreLogo.svg';
import DamText from '../assest/images/DamText.png';
import { getFolders, getLanguageApi, getLoginToken } from '../api/postRequest';
import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '../assest/Toaster';
import { getAuthToken } from '../config/constants';
import { getPermissions, viewProfile } from '../api/getRequest';
import { useNonPersistedStore } from '../store/nonPersistedStore';
import { useStore } from '../store/persistedStore';
import { ReactComponent as Visibility } from '../assest/svg/eye.svg';
import { ReactComponent as VisibilityOff } from '../assest/svg/eyeoff.svg';
import localImage from '../assest/images/LoginPageImage.png'; 


const LoginPage = () => {
  const navigate = useNavigate();
  const [userName,setuserName]=useState();
  const [showPassword, setShowPassword] = useState(false);
  const [profileDataSet, setProfileDataSet] = useState(false); //
  const [userPassword, setuserPassword] = useState();
  const {
    clickedFolderZusNon,
    setClickedFolderZusNon,
    setLanguageOptionsZusNon,
    languageOptionsZusNon,
  } = useNonPersistedStore();
  const {
    setLanguageOptionsZus,
    languageOptionsZus,
    setFolderTreeStructureZus,
    loginUserProfileDataZus,
    setLoginUserProfileDataZus,
  } = useStore();
  const mutationGetpermissions = useMutation((post) => getPermissions(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
        } else {
          showErrorToast(result?.data?.message);
        }
      }
    },
    onError: (error) => {},
  });
  const getLanguageApiMutation = useMutation((post) => getLanguageApi(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          setLanguageOptionsZus(result?.data?.data?.languages);
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  const mutationGetFolders = useMutation((post) => getFolders(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
        
          setFolderTreeStructureZus(result?.data?.data?.items);
        
        
        } else {
        }
      }
    },
    onError: (error) => {},
  });

  const mutationViewProfile = useMutation((post) => viewProfile(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          
          setLoginUserProfileDataZus(result?.data?.data)
          setProfileDataSet(true); // Set
        
        } else {
          showErrorToast(result?.data?.message)

        }
      }
    },
    onError: (error) => { },
  });

  const mutationGetLoginToken = useMutation((post) => getLoginToken(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          localStorage.setItem("token", result?.data?.data?.token);
         
          mutationGetpermissions.mutate();
          setTimeout(() => {
            // navigate({
            //   pathname: "/",
            // });
            mutationGetFolders.mutate()
            mutationViewProfile.mutate()
            // if(folderTreeStructureZus[0]?.clickable){
            //   setClickedFolderZusNon({
            //     label: "Home",
            //     id: 1,
            //   });
            // }
         
          }, 10);
          getLanguageApiMutation.mutate()
        } else {
          showErrorToast(result?.data?.message);
          localStorage.removeItem("token");
        
        }
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (profileDataSet) {
      navigate({
        pathname: "/",
      });
      setProfileDataSet(false); // Reset flag after navigation
    }
  }, [profileDataSet, navigate]);
  
  
  const handleSignIn = () => {
    let payload ={
      email:userName,
      password:userPassword
    };
    mutationGetLoginToken.mutate(payload)
   
    // navigate({
    //   pathname: "/",
    // });
  };

  const handleClickShowPassword = () => {

    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => event.preventDefault();
    
  const handleChangeName = (val) => {
    if (val.startsWith(" ")) {
      return;
    } else {
      setuserName(val);
    }
  };

  const handleChangePassword = (val) => {
    if (val.startsWith(" ")) {
      return;
    } else {
      setuserPassword(val);
    }
  };
  const isButtonDisabled = !userName || !userPassword;
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if(!isButtonDisabled){
        e.preventDefault(); // Prevent the default form submission behavior
        handleSignIn(); // Trigger the sign-in function

      }
    
    }
  };


 
  

  return (
  
    <Grid container className="container" sx={{ background: 'var(--white)'}}>
      
      <Grid item xs={5} sx={{ padding: "4em" }}>
        <DamLogo />
        &nbsp;&nbsp;&nbsp;
        <img src={DamText} alt="" />
        <Box
          className="shareAssetForm col-10 login"
          sx={{
            height: "90%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">Member Login</Typography>

          <FormControl variant="standard" className='mt-2'>
            <label>
              User name<span class="required">*</span>
            </label>

            <TextField
              type="text"
              placeholder="Please enter user name"
              className="textbox"
              value={userName}
              onKeyDown={handleKeyDown} // Add keydown event handler
              onChange={(e) => handleChangeName(e.target.value)}
              variant="standard"
            />
          </FormControl>
          <FormControl variant="standard">
            <label>
              Password<span class="required">*</span>
            </label>
            <TextField
              type={showPassword ? "text" : "password"}
              placeholder="Please enter password"
              className="textbox"
              value={userPassword}
              onKeyDown={handleKeyDown} // Add keydown event handler
              onChange={(e) => handleChangePassword(e.target.value)}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className="eyeIcon"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          
          <Button
            variant="filled"
            sx={{width: '30px', marginLeft: '0 !important', marginTop: '0.5em'}}
            className="button"
            onClick={handleSignIn}
            disabled={isButtonDisabled || mutationGetLoginToken?.isLoading}
          >
            Login
          </Button>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <PimcoreLogo />
        </Box>
      </Grid>
      <Grid
        item
        xs={7}
        sx={{
          background: `url(${localImage}) no-repeat`,
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          backgroundPosition: 'right'
        }}
      ></Grid>
      {/* <Grid
        item
        xs={8}
        sx={{
          background:
            "URL('https://png.pngtree.com/thumb_back/fh260/background/20201028/pngtree-abstract-technology-background-technical-electric-image_443494.jpg') no-repeat",
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
        }}
      ></Grid> */}
    </Grid>

    // <div>
    //   {" "}
    //   <Button
    //     onClick={handleSignIn}
    //     // disabled={!email || !password}
    //   >
    //     {" "}
    //     Sign In
    //   </Button>
    // </div>
  );
};

export default LoginPage