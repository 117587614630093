import { Badge, Box, Button, Chip, Divider, Drawer, FormControl, FormGroup, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import { menuStyles, paperPropsStyles } from "../styledComponents/MenuStyles"; 
import { menuStylesUserNameAndSettings, paperPropsStylesUSerNAmeAndSettings } from "../styledComponents/MenuStylesUserNameAndSettings"; 

import { ReactComponent as MailIcon } from '../assest/svg/MailIcon.svg';
import { ReactComponent as NotificationsIcon } from '../assest/svg/NotificationsIcon.svg';
import { ReactComponent as Profile } from '../assest/svg/profile.svg';
import { ReactComponent as Search } from '../assest/svg/search.svg';
import { ReactComponent as DamLogo } from '../assest/svg/DamLogo.svg';
import { ReactComponent as Download } from '../assest/svg/downloadicon.svg';
import { ReactComponent as Edit } from '../assest/svg/editicon.svg';
import { ReactComponent as ShareHistory } from '../assest/svg/sharehistory.svg';

import { ReactComponent as Move } from '../assest/svg/moveicon.svg';
import { ReactComponent as Delete } from '../assest/svg/deleteicon.svg';
import { ReactComponent as Close } from '../assest/svg/close.svg';
import { ReactComponent as Cross } from "../assest/svg/cross.svg";
import Dropzone from "react-dropzone";
// import {ReactComponent as DamText} from '../assest/svg/DamText.svg';
import DamText from '../assest/images/DamText.png';
import { ReactComponent as PimcoreLogo } from '../assest/svg/pimcoreLogo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteCartItem, getFirstPageListing, getUserCart, updateUserProfile } from '../api/postRequest';
import { useStore } from '../store/persistedStore';
import { useMutation } from '@tanstack/react-query';
import Loader from './Loader';
import { useNonPersistedStore } from '../store/nonPersistedStore';
import { showErrorToast, showSuccessToast } from '../assest/Toaster';

import { ReactComponent as Visibility } from '../assest/svg/eye.svg';
import { ReactComponent as VisibilityOff } from '../assest/svg/eyeoff.svg';
import { ReactComponent as Logout } from '../assest/svg/logout.svg';
import { ReactComponent as Setting } from '../assest/svg/settingn.svg';
import { ReactComponent as Person } from '../assest/svg/person.svg';
import { withStyles} from "@material-ui/core/styles";
import DamPortalModal from './DamPortalModal';
import { viewProfile } from '../api/getRequest';
import { useCallback } from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PasswordField from './PasswordField';


const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);



const DamHeader = () => {

  const location = useLocation();
  const {
    setMainPageListingArr,
    mainPageListingArr, setClickedFolderZus,setTotalCountZustand,setLoginUserProfileDataZus,loginUserProfileDataZus

  } = useStore();
  const {
    setFilterMasalaZusNon,
    filterMasalaZusNon,
    clickedFolderZusNon, setClickedFolderZusNon,userCartListZusNon, setUserCartListingZusNon,  userCartListTotalCountZusNon,
    setUserCartListingTotalCountZusNon
  } = useNonPersistedStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [profileDataFromApi, setProfileDataFromApi] = useState(null);
  const [editUserProfile, setEditUserProfile] = useState(false);
  const [editAvtar, setEditAvtar] = useState(false);
  // const [profileEmail, setProfileEmail] = useState(profileDataFromApi?.email);
  const [profileEmail, setProfileEmail] = useState(loginUserProfileDataZus?.email);
  const [profileFirstName, setProfileFirstName] = useState(loginUserProfileDataZus?.firstName);
  const [profileLastName, setProfileLastName] = useState(loginUserProfileDataZus?.lastName)
  const [editPasswordUserProfile, setEditPasswordUserProfile] = useState();
  const [showInstructions, setShowInstructions] = useState(true);
  const [profilePassword, setProfilePassword] = useState();
  const [file, setFile] = useState(null);

  const [updateProfile, setUpdateProfile] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  
  const [passwordUpdate,setPasswordUpdate] = useState('');
  const [emailError, setEmailError] = useState('');


  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const open = Boolean(anchorEl);
  const openProfile = Boolean(anchorElProfile);

  const [cartItems, setCartItems] = useState([])

  const [searchValue, setSearchValue] = useState(location?.state && location?.state?.searchTerm)
  const [toggleSearch, setToggleSearch] = useState(true)

  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem("token");
    navigate({
      pathname: "/login",
    });
    setClickedFolderZus("")
    setClickedFolderZusNon("")
    setUserCartListingZusNon([])
    setUserCartListingTotalCountZusNon(null)
    setFilterMasalaZusNon(null)

  };

  const onDrop = useCallback((acceptedFiles) => {
    // Ensure only one file is accepted
    if (acceptedFiles.length > 0) {
      setFile(
        acceptedFiles[0]
        
      );
    }
  }, []);

  const removeFile = () => {
    setFile(null);
    
  };

  const mutationGetUserCart = useMutation((post) => getUserCart(post), {
    onSuccess: (result) => {
      if (result?.data?.success) {
       
        setCartItems(result?.data?.data?.entries)
   
       setUserCartListingTotalCountZusNon(result?.data?.data?.totalResults)

        setUserCartListingZusNon(result?.data?.data?.entries)
      } else {
        if(location?.pathname){
          // showErrorToast(result.data?.message);
        }
        
      }
    },
    onError: (error) => { },
  });

  useEffect(() => {
      let payload = {}
      mutationGetUserCart.mutate(payload)
  }, [open])

  const mutation = useMutation((post) => getFirstPageListing(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {

          let output = result.data?.data?.items
          const newArray = output?.map((obj) => ({ ...obj, ["isChecked"]: false }))
          setMainPageListingArr(newArray)
          setTotalCountZustand(result.data?.data?.totalResults);
        } else {

        }
      }
    },
    onError: (error) => { },
  });

  const mutationViewProfile = useMutation((post) => viewProfile(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {

          setLoginUserProfileDataZus(result?.data?.data)
          
        //  setProfileDataFromApi(result?.data?.data)
         setProfileFirstName(result?.data?.data?.firstName)
         setProfileEmail(result?.data?.data?.email)
         setProfileLastName(result?.data?.data?.lastName)
        } else {

        }
      }
    },
    onError: (error) => { },
  });

  useEffect(() => {
    mutationViewProfile.mutate();
  }, [])
  

  const mutationUpdateUserProfile = useMutation(
    (post) => updateUserProfile(post),
    {
      onSuccess: (result) => {
        if (result) {
          setShowInstructions(true)
          if (result?.data?.success) {
            mutationViewProfile.mutate();
            showSuccessToast(result?.data?.message);
            if (passwordUpdate) {
              localStorage.removeItem("token");
              navigate({
                pathname: "/login",
              });
            }
            setPasswordUpdate(false);
            setUpdateProfile(false);
            setEditUserProfile(false);
            setEditPasswordUserProfile(false);
            setEditAvtar(false);
            setFile(null);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
          } else {
            showErrorToast(result?.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );


 
  
  const handleNavigation = () => {
    if(location?.pathname !=="/"){
      navigate({
        pathname: "/",
      });
      // setFilterMasalaZusNon(null)
    

    }

  
  };


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      
      if (searchValue) {
        mutation.mutate({ searchTerm: searchValue });
        navigate(
          {
            pathname: "/searchResultsPage",
            search:`id=${searchValue}`,
          },
          {
            state: {
              searchTerm: searchValue,
            },
          }
        );
      } else {
        navigate({
          pathname: "/",
        });
      }
    }
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
    // mutationViewProfile.mutate();
  };

  const handleClickMyProfile = (event) => {
    setUpdateProfile(true);
  };
  const handleClickPublicShare = (event) => {
    navigate(`/publicShareHistoryPage`);
  };

  

  const handleCallbackUpdateProfile = (
    profileFirstName,
    profileLastName,
    profileEmail
  ) => {
 
    let payload = {
      firstname: profileFirstName,
      lastname: profileLastName,
      email: profileEmail,
    };
    let passowordPayload = {
      password: profileFirstName,
    };

    if (editPasswordUserProfile) {
      mutationUpdateUserProfile.mutate(passowordPayload);
    } else {
    
      mutationUpdateUserProfile.mutate(payload);
    }
  };

  const handleCloseModal = () => {
    
    setUpdateProfile(false);
    setEditPasswordUserProfile(false)
    setCurrentPassword(null)
    setEditUserProfile(false)
    setNewPassword("")
    setConfirmPassword("")
    setError("")
    setFile(null)
    setEditAvtar(false)
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const mutationDeleteCartItem = useMutation((post) => deleteCartItem(post), {
    onSuccess: (result) => {
      if(result?.data?.success){
        showSuccessToast(result.data?.message)
        mutationGetUserCart.mutate()
      } else {
        showErrorToast(result.data?.message);
      }
    },
    onError: (error) => {},
  });

  const handleDeleteCartItem = (itemKey) => {
    let payload = { itemKey : itemKey }
    mutationDeleteCartItem.mutate(payload)
  }

  const handleDownload = (id) => {
    window.open(
      `https://dam-api.pimcoredemos.com/api/download-asset/${id}`,"_self"
      // "_blank"
    );
    setTimeout(() => {
      showSuccessToast("Asset downloaded succesfully");
    }, 2000);
  }
  const handleSavedSearches = () => {
    navigate({
      pathname: "/savedSearchesPage",
    });
  };

  const handleEditUserProfileClick = () => {
    setEditUserProfile(true);
  };

  const handleEditPasswordUserProfileClick = () => {
    setEditPasswordUserProfile(true);
  };

  const handleCancelUserProfileClick = () => {
    setEditUserProfile(false);
    setEditPasswordUserProfile(false);
    setEditAvtar(false)
    setFile(null)
    mutationViewProfile.mutate()
  };

  const handleCancelUserProfilePasswordClick = () => {
    setShowInstructions(true)
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setError('');
    setEditUserProfile(false);
    setEditPasswordUserProfile(false);
    setEditAvtar(false)
    setFile(null)
    mutationViewProfile.mutate()
  };
  

  const handleChangeAvtar = () => {
    setEditAvtar(true);
  };

  const handleUploadAvtar = () => {
    if (editAvtar) {
      let payloadFile = {
        profileImage: file,
      };

      mutationUpdateUserProfile.mutate(payloadFile);
    }
  };

  

  

  const handlePasswordChange = () => {
    // Check if current password matches the one in the database
    // if (currentPassword !== 'currentPasswordInDatabase') {
    //   setError('Current password is incorrect.');
    //   return;
    // }

    // if (
    //   currentPassword.trim() === "" ||
    //   newPassword.trim() === "" ||
    //   confirmPassword.trim() === ""
    // ) {
    //   setError("Please enter all mandatory fields.");
    //   return;
    // }

    if(currentPassword=="" || newPassword==""|| confirmPassword=="" )
    {
      setError('Please enter all mandatory fields.');
      return;
    }
    // Check if all passwords are the same
    if (currentPassword === newPassword && newPassword === confirmPassword) {
      setError("New password cannot be same as current password.");
      return;
    }

    // // Check if new password meets criteria (e.g., minimum length)
    // if (newPassword?.length < 6) {
    //   setError('New password must be at least 8 characters long.');
    //   return;
    // }

    // Check if new password matches confirm password
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }
    let regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&-]{8,15}$/;
    let E = (
      <div>
        New password must contain.
        <ul>
          <li>At least one lowercase alphabet i.e. [a-z] </li>{" "}
          <li> At least one uppercase alphabet i.e. [A-Z] </li>
          <li>At least one Numeric digit i.e. [0-9] </li>
          <li>
            {" "}
            At least one special character i.e. [‘@’, ‘$’, ‘.’, ‘#’, ‘!’, ‘%’,
            ‘*’, ‘?’, ‘&’, ‘^’]{" "}
          </li>
          <li> Also, the total length must be in the range [8-15] </li>
        </ul>
      </div>
    );
    if (!regex.test(newPassword)) {
      setError(E);
      setShowInstructions(false)
      return;
    }
    setError("");
    let passowordPayload = {
      password: newPassword,
      oldpassword: currentPassword,
    };

    if (editPasswordUserProfile) {
      setPasswordUpdate(true);
      mutationUpdateUserProfile.mutate(passowordPayload);
      //localStorage.removeItem("token")
    } else {
    }
  };
  const instructionForUser= (
    <div>
      New password must contain:
      <ul style={{ listStyleType: 'none' }}>
        <li>At least one lowercase alphabet i.e. [a-z]</li>
        <li>At least one uppercase alphabet i.e. [A-Z]</li>
        <li>At least one numeric digit i.e. [0-9]</li>
        <li>At least one special character i.e. [‘@’, ‘$’, ‘.’, ‘#’, ‘!’, ‘%’, ‘*’, ‘?’, ‘&’, ‘^’]</li>
        <li>The total length must be in the range [8-15]</li>
      </ul>
    </div>
  );
  function checkURL(url) {
    return url.includes("/api/public-share/list") || url.includes("/api/public-share/collectionlist");
  }
  
const handleCloseSearch=()=>{
  setSearchValue("");
  document.getElementById('searchText').focus();
}


const handleChangeGlobalSearch = (e) => {
  let value = e.target.value;
  
  // Remove leading spaces
  if (value.startsWith(' ')) {
    value = value.trimStart();
  }
  
  // Limit to 50 characters
  if (value.length > 50) {
    value = value.slice(0, 50);
  }

  setSearchValue(value);
};

const handleEmailChange = (e) => {
  const newValue = e.target.value;
  setProfileEmail(newValue);

  // Validate email
  if (!emailRegex.test(newValue)) {

    setEmailError('Invalid email address');
  } else {
   
    setEmailError('');
  }
};




const handleClickShowPassword = () => {

  setShowPassword(!showPassword);
};
const handleClickShowNewPassword = () => {

  setShowNewPassword(!showNewPassword);
};

const handleClickShowConfirmPassword = () => {

  setShowConfirmPassword(!showConfirmPassword);
};

const currentPasswordRef = useRef(null);


const handleMouseDownPassword = (event) => event.preventDefault();
const handleMouseDownNewPassword = (event) => event.preventDefault();
const handleMouseDownConfirmPassword = (event) => event.preventDefault();

return (
  <>
    {mutation.isLoading && <Loader></Loader>}
    {/* {mutationViewProfile.isLoading && <Loader></Loader>} */}
    {mutationUpdateUserProfile.isLoading && <Loader></Loader>}

    {/* <Box>
        <DamPortalModal
          open={updateProfile}
          updateProfileModalFlag={updateProfile}
          profileDataFromApi={profileDataFromApi}
          onClose={handleCloseModal}
          titleMessage={"Update Profile"}
          handleCallbackUpdateProfile={handleCallbackUpdateProfile}
        />
      </Box> */}

    <Drawer
      open={updateProfile}
      anchor="right"
      className="uploadAsset profileDrawer"
    >
      <Typography component="h5" variant="h5" className="drawertitle">
        <Move height={25} /> <span className="marginL-xs">Profile</span>
        <Cross className="closed" onClick={() => handleCloseModal()} />
      </Typography>
      <Box className="">
        <Grid container>
          <Grid item xs={12} className="manageFolder">
            <Box
              className="cardMedia profileWrapper"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {loginUserProfileDataZus?.profileImage ? (
                <img
                  src={`${loginUserProfileDataZus?.profileImage}`}
                  height="190px"
                  width="160px"
                  className="profile-image"

                  // title="green iguana"
                />
              ) : (
                <Profile height="190" className="profileDefault" />
              )}

              {!(editUserProfile || editPasswordUserProfile || editAvtar) && (
                <>
                  <Button
                    className="button mt"
                    onClick={() => handleChangeAvtar()}
                    startIcon={<Edit />}
                  >
                    Edit profile image
                    {/* Update picture */}
                  </Button>
                </>
              )}
            </Box>
            <>
              {editAvtar ? (
                <>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "1em",
                      cursor: "pointer",
                    }}
                  >
                    <Dropzone
                      accept={{ "image/*": [] }}
                      onDrop={onDrop}
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <Box
                            {...getRootProps()}
                            // style={dropzoneStyle}
                            sx={{
                              border: "1px dashed var(--border)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "1em 0",
                              width: "100%",
                              minHeight: "200px",
                              boxSizing: "border-box",
                              flexDirection: "column",
                              fontSize: "var(--fontSizeM)",
                            }}
                          >
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop a file here </p>
                            <p className="padding-sm">OR</p>
                            <p className="button link">
                              Click to select a file
                            </p>
                          </Box>
                          <Box className="notification col-12">
                            Only upload image file format
                          </Box>

                          {file && (
                            // <Box className="filePath">
                            //   <p>{file.name}</p>
                            //   <Button
                            //     className="button icon"
                            //     startIcon={<Close />}
                            //     onClick={removeFile}
                            //   ></Button>
                            // </Box>

                            <Box
                              className="dataBox col-12"
                              sx={{ marginTop: "1em", marginBottom: "5em" }}
                            >
                              <Typography variant="h6">
                                Uploaded image
                              </Typography>
                              <Box className="formBox1">
                                <Typography className="flex" mr={2}>
                                  {file?.name}
                                </Typography>

                                <Cross
                                  onClick={removeFile}
                                  cursor={"pointer"}
                                />
                              </Box>
                            </Box>
                          )}
                          {/* {file && Object.keys(file)?.length != 0 && (
                          <Box className="drawerFooter">
                            {handleUploadFileModal()}
                          </Box>
                        )} */}
                        </>
                      )}
                    </Dropzone>
                  </Box>
                </>
              ) : editPasswordUserProfile ? (
                <>
                  {showInstructions && (
                    <Box
                      sx={{ padding: "1em" }}
                      className="notificationWrapper col-12"
                    >
                      <Box className="notification col-12">
                        {instructionForUser}
                      </Box>{" "}
                    </Box>
                  )}
                  {error && <p className="error-message">{error}</p>}
                  <FormGroup
                    className="shareAssetForm paddingInterchnage"
                    sx={{ paddingBottom: "100px" }}
                  >
                    <FormControl variant="standard">
                      <label>Current password</label>
                      <span class="required">*</span>
                      <PasswordField
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        showPassword={showCurrentPassword}
                        toggleShowPassword={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                      />
                      {/* <TextField
                        inputRef={currentPasswordRef}
                        id="outlined-basic"
                        label=""
                        type={showPassword ? "text" : "password"}
                        value={currentPassword}
                        className="textbox"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                className="eyeIcon"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      /> */}
                    </FormControl>
                    <FormControl variant="standard">
                      <label>New password</label>
                      <span class="required">*</span>
                      <PasswordField
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        showPassword={showNewPassword}
                        toggleShowPassword={() =>
                          setShowNewPassword(!showNewPassword)
                        }
                      />
                      {/* <TextField
                        id="outlined-basic"
                        label=""
                        type={showNewPassword ? "text" : "password"}
                        value={newPassword}
                        className="textbox"
                        onChange={(e) => setNewPassword(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownNewPassword}
                                edge="end"
                                className="eyeIcon"
                              >
                                {showNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      /> */}
                    </FormControl>
                    <FormControl variant="standard">
                      <label>Confirm password</label>
                      <span class="required">*</span>
                      <PasswordField
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        showPassword={showConfirmPassword}
                        toggleShowPassword={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                      {/* <TextField
                        id="outlined-basic"
                        label=""
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        className="textbox"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                edge="end"
                                className="eyeIcon"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      /> */}
                    </FormControl>
                  </FormGroup>
                </>
              ) : (
                <>
                  <FormGroup className="shareAssetForm paddingInterchnage">
                    <FormControl variant="standard">
                      <label>
                        First name <span class="required">*</span>
                      </label>
                      <TextField
                        id="outlined-basic"
                        label=""
                        // defaultValue={profileDataFromApi?.firstName}
                        value={profileFirstName}
                        className="textbox"
                        disabled={!editUserProfile ? true : false}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setProfileFirstName(newValue);
                        }}
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                      />
                    </FormControl>

                    <FormControl variant="standard">
                      <label>
                        Last name <span class="required">*</span>
                      </label>
                      <TextField
                        id="outlined-basic"
                        // defaultValue={profileDataFromApi?.lastName}
                        label=""
                        value={profileLastName}
                        className="textbox"
                        disabled={!editUserProfile ? true : false}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setProfileLastName(newValue);
                        }}
                        onKeyDown={handleKeyPress}
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                      />
                    </FormControl>

                    <FormControl variant="standard">
                      <label>
                        Email <span class="required">*</span>
                      </label>

                      <TextField
                        id="outlined-basic"
                        // defaultValue={profileDataFromApi?.email}
                        // disabled={!editUserProfile ? true : false}
                        disabled={true}
                        label=""
                        value={profileEmail}
                        className="textbox"
                        // onChange={(e) => {
                        //   const newValue = e.target.value;

                        //   setProfileEmail(newValue);
                        // }}
                        // onKeyDown={handleKeyPress}
                        onChange={handleEmailChange}
                        variant="standard"
                        inputProps={{ maxLength: 100 }}
                        error={!!emailError} // Display error state
                        helperText={emailError} // Show error message
                      />
                    </FormControl>
                    <FormControl variant="standard">
                      <Button
                        className="button link"
                        onClick={() => handleEditPasswordUserProfileClick()}
                      >
                        Edit password
                      </Button>
                    </FormControl>
                  </FormGroup>
                </>
              )}
              {editUserProfile ? (
                <>
                  <Box className="drawerFooter">
                    <Button
                      className="button"
                      // variant="filled"
                      onClick={() => handleCancelUserProfileClick()}
                      // onClick={setEditUserProfile(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="button"
                      variant="filled"
                      disabled={
                        profileFirstName === "" ||
                        profileLastName === "" ||
                        profileEmail === "" ||
                        !!emailError
                      }
                      onClick={() =>
                        handleCallbackUpdateProfile(
                          profileFirstName,
                          profileLastName,
                          profileEmail
                        )
                      }
                    >
                      Update
                    </Button>
                  </Box>
                </>
              ) : editPasswordUserProfile ? (
                <>
                  {" "}
                  {/* {error && <p className="error-message">{error}</p>} */}
                  <Box className="drawerFooter">
                    <Button
                      className="button"
                      // variant="filled"
                      onClick={() => handleCancelUserProfilePasswordClick()}
                      // onClick={setEditUserProfile(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="button"
                      variant="filled"
                      onClick={() => handlePasswordChange()}
                    >
                      Update
                    </Button>
                  </Box>
                </>
              ) : (
                <Box className="drawerFooter">
                  {editAvtar ? (
                    <>
                      <Button
                        className="button"
                        // variant="filled"
                        onClick={() => handleCancelUserProfileClick()}
                        // onClick={setEditUserProfile(false)}
                      >
                        Cancel
                      </Button>

                      <Button
                        className="button"
                        variant="filled"
                        disabled={!file}
                        onClick={() => handleUploadAvtar()}
                      >
                        Upload image
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="button"
                        variant="filled"
                        onClick={() => handleEditUserProfileClick()}
                      >
                        Edit
                      </Button>
                      {/* <Button
                          className="button"
                          variant="filled"
                          onClick={() => handleEditPasswordUserProfileClick()}
                        >
                          Edit password
                        </Button> */}
                    </>
                  )}
                </Box>
              )}
            </>
          </Grid>
        </Grid>
      </Box>
    </Drawer>

    <Box className="topMenu">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
              borderRadius: 1,
              bgcolor: "background.paper",
              color: "text.secondary",
              "& svg": {
                m: 1.5,
              },
              "& hr": {
                mx: 0.5,
              },
            }}
          >
            <Box
              onClick={handleNavigation}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                paddingRight: "12px",
              }}
            >
              <DamLogo style={{ marginLeft: "0" }} />
              <img src={DamText} alt="" className="tabView" />
            </Box>

            <Divider orientation="vertical" variant="middle" flexItem />
            <PimcoreLogo />
          </Box>
        </Grid>
        {!checkURL(location?.pathname) && (
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                id="searchText"
                label=""
                className="searchField"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        cursor="pointer"
                        height={25}
                        // onClick={() => setToggleSearch(!toggleSearch)}
                        onClick={() => setToggleSearch(true)}
                      />
                    </InputAdornment>
                  ),
                  //endAdornment: !toggleSearch && (
                  endAdornment: (
                    <InputAdornment position="start">
                      {searchValue?.length ? (
                        <Close
                          cursor="pointer"
                          height={18}
                          onClick={() => handleCloseSearch()}
                        />
                      ) : (
                        ""
                      )}
                    </InputAdornment>
                  ),
                }}
                // style={toggleSearch ? {width: 50}:{width: 200}}
                //className={toggleSearch ? "largeSearch" : ""}
                placeholder="Search"
                value={searchValue}
                onChange={handleChangeGlobalSearch}
                // onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleKeyPress}
                variant="standard"
              />

              {location?.pathname !== "/cartPage" && (
                <TooltipStyle title="Cart">
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                    // onClick={handleClick}
                    onClick={() => navigate("/cartPage")}
                    sx={{ marginRight: "5px !important" }}
                    aria-controls={open ? "ing" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    className="button icon"
                  >
                    {/* <Badge badgeContent={userCartListZusNon?.length}> */}

                    <Box position="relative" display="inline-block">
                      {userCartListTotalCountZusNon > 0 && (
                        <Badge
                          badgeContent={userCartListTotalCountZusNon}
                          // color="primary"
                          overlap="circle"
                          className="customBadge"
                        />
                      )}
                      <MailIcon />
                    </Box>
                  </IconButton>
                </TooltipStyle>
              )}

              <Menu
                anchorEl={anchorEl}
                id="ing"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                {...paperPropsStyles}
                //  {   ...paperPropsStylesUSerNAmeAndSettings}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem sx={{ color: "var(--black)" }}>
                  Cart
                  <Chip
                    label={cartItems?.length}
                    sx={{
                      fontWeight: "bold",
                      background: "transparent !important",
                      color: "var(--black) !important",
                    }}
                  />
                </MenuItem>
                {cartItems?.slice(0, 3)?.map((value) => (
                  <MenuItem key={value?.elementId} onClick={handleClose}>
                    <Grid container>
                      <Grid item xs={8} className="imageSet">
                        <img
                          src={`${value?.elementURL}`}
                          height="60px"
                          width="60px"
                          title={`${value?.elementName}`}
                        />
                        {value?.elementName?.length > 25
                          ? value?.elementName?.slice(0, 25) + "..."
                          : value?.elementName}
                      </Grid>
                      <Grid item xs={4}>
                        <TooltipStyle title="Download">
                          <Button
                            onClick={() => handleDownload(value?.elementId)}
                            size="small"
                            className="button icon"
                          >
                            <Download />
                          </Button>
                        </TooltipStyle>
                        <TooltipStyle title="Delete">
                          <Button
                            size="small"
                            className="button icon"
                            onClick={() => handleDeleteCartItem(value?.itemKey)}
                          >
                            <Delete />
                          </Button>
                        </TooltipStyle>
                        <TooltipStyle title="Edit">
                          <Button size="small" className="button icon">
                            <Edit />
                          </Button>
                        </TooltipStyle>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
                <MenuItem onClick={() => navigate({ pathname: "/cartPage" })}>
                  View all cart
                </MenuItem>
              </Menu>
              {/* <TooltipStyle title="Notification">
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <Badge badgeContent={17}>
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </TooltipStyle> */}
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                onClick={handleClickProfile}
                aria-controls={openProfile ? "Profile" : undefined}
                aria-haspopup="true"
                aria-expanded={openProfile ? "true" : undefined}
                className="profileImageWrapper"
                sx={{ paddingLeft: "0", marginLeft: "0" }}
              >
                {loginUserProfileDataZus?.profileImage ? (
                  <img
                    src={`${loginUserProfileDataZus?.profileImage}`}
                    height="20px"
                    width="20px"
                    className="profileSmallImage"

                    // title="green iguana"
                  />
                ) : (
                  <Profile height="20" />
                )}

                {/* <Profile height="20" /> */}
              </IconButton>
              <Menu
                anchorEl={anchorElProfile}
                id="Profile"
                open={openProfile}
                onClose={handleCloseProfile}
                onClick={handleCloseProfile}
                disableScrollLock={true}
                // {...paperPropsStyles}
                {...paperPropsStylesUSerNAmeAndSettings}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {mutationViewProfile.isLoading ? (
                  <Skeleton count={2} width={200} height={20} />
                ) : (
                  <MenuItem>
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      className="profileImageWrapper"
                      sx={{
                        marginLeft: "0 !important",
                        marginRight: "0 !important",
                        padding: "0 5px 0 0 !important",
                      }}
                    >
                      {loginUserProfileDataZus?.profileImage ? (
                        <img
                          src={`${loginUserProfileDataZus?.profileImage}`}
                          height="20px"
                          width="20px"
                          className="profileSmallImage"
                        />
                      ) : (
                        <Profile height="20" />
                      )}
                    </IconButton>
                    {/* <Profile height="80" /> */}
                    <Typography component="h6">
                      {loginUserProfileDataZus?.firstName}
                      <br />
                      <span>{loginUserProfileDataZus?.email}</span>
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={handleClickMyProfile}>
                  <Person /> My profile
                </MenuItem>
                {/* <MenuItem onClick={handleClickPublicShare}>
                  <ShareHistory /> Public share
                </MenuItem> */}

                {/* <MenuItem onClick={handleSavedSearches}>
                   Saved Searches
              </MenuItem> */}

                <MenuItem onClick={handleLogOut}>
                  <Logout />
                  Sign out
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  </>
);
}

export default DamHeader