import {
  Box,
  
  Button,
  Drawer,
  Tooltip,
  TextareaAutosize,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import CustomMoment from 'moment-timezone';
import DatePicker from "react-datepicker";
import { ReactComponent as ManageFolder } from "../assest/svg/ManageFolder.svg";
import { ReactComponent as UploadAsset } from "../assest/svg/UploadAsset.svg";
import { menuStyles, paperPropsStyles } from "../styledComponents/MenuStyles";
import { getFirstPageListing, getLanguageApi } from "../api/postRequest";
import { ReactComponent as DownArrow } from "../assest/svg/chevronDown.svg";
import { ReactComponent as Asset } from "../assest/svg/asset.svg";
import { ReactComponent as Channel } from "../assest/svg/channel.svg";
import { ReactComponent as Close } from "../assest/svg/cross.svg";

import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropzone from "react-dropzone";
import {
  getCollectionModalTags,
  uploadAssetFromUrl,
  uploadSingleAssetFile,
  uploadZip,
} from "../api/postRequest";
import { useMutation } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import { useStore } from "../store/persistedStore";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { uploadUrlSchema } from "../schemas/uploadUrlSchema";
import { ErrorMessageStyled } from "../styledComponents/ErrorMessageStyled";

import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import DamPortalModal from "../components/DamPortalModal";
// import ReactSelect from 'react-select';
import { default as ReactSelectt } from "react-select";

import { ReactComponent as Cross } from "../assest/svg/cross.svg";


import { ReactComponent as ManagesFolder } from "../assest/svg/ManageFolder.svg";
import { manageforlderCruDArray } from "../utils/constant";

import { withStyles } from "@material-ui/core/styles";
import commonMessages from "../utils/commomMessages";
import {ReactComponent as Noimage} from '../assest/svg/NoImage.svg';
import { handleKeyDown } from "../utils/handleKeyDownEvent";
const Selector = () => {
  const [open, setOpen] = React.useState(false);
  const [nameError, setNameError] = useState(false);
  const [languageOptions, setLanguageOptions] = useState();

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
    setFile(null);
    reset();
    setZipFile(null);
    handleCloseModal();
    setTags([]);
    setMetaData([]);
    setRename("");
  };

  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };

  const navigate = useNavigate();
  const [block, setBlock] = useState("File");
  const [isRenameModal, setIsRenameModal] = useState(false);
  const [isAddfolderModal, setIsAddfolderModal] = useState(false);
  const [isDeletefolderModal, setIsDeletefolderModal] = useState(false);
  const [searchParams] = useSearchParams();
  const [file, setFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUploadFileModal, setIsUploadFileModal] = useState(false);
  const [webUrlData, setWebUrlData] = useState();
  const [zipFile, setZipFile] = useState(null);
  const [uploadFileTags, setUploadFileTags] = useState();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [rename, setRename] = useState("");
  const [tags, setTags] = useState([]);
  const open1 = Boolean(anchorEl1);
  const [newMetaData, setNewMetaData] = useState({
    idx: Math.random(),
    name: "",
    language: "",
    type: "",
    data: "",
  });
const[deleteFolderSuccess,setDeleteFolderSuccess] = useState(false)
  const [metadata, setMetaData] = useState([]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 31,
      minHeight: 31,
      fontSize: "14px",
      margin: "0 10px",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      height: 24,
    }),
    clearIndicator: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
      height: 24,
    }),
    // menuPortal: (base) => ({ ...base, zIndex: 9999 })
  };

  const TooltipStyle = withStyles({
    tooltip: {
      color: "var(--black) !important",
      backgroundColor: "var(--checked) !important",
      fontSize: "var(--fontSize) !important",
      "& .MuiTooltip-arrow": {
        color: "var(--checked)",
      },
    },
  })(Tooltip);
  const inputOptions = [
    { label: "Input", value: "in" },
    { label: "Checkbox", value: "check" },
    { label: "TextArea", value: "area" },
    { label: "Date", value: "date" },
  ];

  const {
    setMainPageListingArr,
    mainPageListingArr,
    setTotalCountZustand,
    setFiltersZustand,
    clickedFolderZus,    setLanguageOptionsZus,
    languageOptionsZus,
  } = useStore();
  const {
    clickedFolderZusNon,selectedMimeTypeZusNon,sortingZusNon,
    setClickedFolderZusNon,
    setSelectedMimeTypeZusNon,languageOptionsZusNon
  } = useNonPersistedStore();

  const getLanguageApiMutation = useMutation((post) => getLanguageApi(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          setLanguageOptions(result?.data?.data?.languages);
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  // useEffect(() => {
  //   getLanguageApiMutation.mutate();
  // }, []);

  const mutation = useMutation((post) => getFirstPageListing(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          let output = result.data?.data?.items;
          const newArray = output?.map((obj) => ({
            ...obj,
            ["isChecked"]: false,
          }));
          setMainPageListingArr(newArray);
          setTotalCountZustand(result.data?.data?.totalResults);
          setDeleteFolderSuccess(false)
        } else {
        }
      }
    },
    onError: (error) => {},
  });

  const handleNavigation = (data) => {
    navigate({
      pathname: "/uploadAssetsPage",
      search: `folderId=${clickedFolderZusNon?.id}&folderLabel=${clickedFolderZusNon?.label}`,
    });
  };

  const handleNavigationFolder = (data) => {
    navigate({
      pathname: "/manageFolderPage",
    });
  };


  // const { clickedFolderZus, setClickedFolderZus } = useStore();
  // const { clickedFolderZusNon, setClickedFolderZusNon } = useNonPersistedStore();

  const acceptedMimeTypes = ["application/zip", "application/x-zip-compressed"];
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors,isValid },
  } = useForm({
     resolver: yupResolver(uploadUrlSchema),
    defaultValues: {},
  });

  const uploadSingleAssetFileMutation = useMutation(
    (post) => uploadSingleAssetFile(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {

            let payload = {
              
              folderName: clickedFolderZusNon.label,
              folderId:  clickedFolderZusNon?.id ,
              mimetype: selectedMimeTypeZusNon?.mimetype,
              sortBy: sortingZusNon && sortingZusNon[0],
              sortOrder: sortingZusNon && sortingZusNon[1],
            };
            mutation.mutate(payload);
            //  mutation.mutate({assetId:detailPageData?.id})
            toggleDrawer(false);
            showSuccessToast(result?.data?.message);
            // removeFile();
          } else {
            showErrorToast(result?.data?.message)
          }
        }
      },
      onError: (error) => {},
    }
  );
  const uploadFromUrlMutation = useMutation(
    (post) => uploadAssetFromUrl(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            // mutation.mutate({assetId:detailPageData?.id})
            let payload = {
              
              folderName: clickedFolderZusNon.label,
              folderId:  clickedFolderZusNon?.id ,
              mimetype: selectedMimeTypeZusNon?.mimetype,
              sortBy: sortingZusNon && sortingZusNon[0],
              sortOrder: sortingZusNon && sortingZusNon[1],
            };
            mutation.mutate(payload);
            toggleDrawer(false);
            showSuccessToast(result?.data?.message);
          } else {
            showErrorToast(result?.data?.message)
          }
        }
      },
      onError: (error) => {},
    }
  );
  const uploadZipMutation = useMutation((post) => uploadZip(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          // mutation.mutate({assetId:detailPageData?.id})
          showSuccessToast(result?.data?.message);
          let payload = {
            folderName: clickedFolderZusNon.label,
            folderId: clickedFolderZusNon?.id,
            mimetype: selectedMimeTypeZusNon?.mimetype,
            sortBy: sortingZusNon && sortingZusNon[0],
            sortOrder: sortingZusNon && sortingZusNon[1],
          };
          mutation.mutate(payload);
          toggleDrawer(false);
          removeFile();
        } else {
          showErrorToast(result?.data?.message);
        }
      }
    },
    onError: (error) => {
      showErrorToast("Error occured");
    },
  });
  const getCollectionModalTagsMutation = useMutation(
    (post) => getCollectionModalTags(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            setUploadFileTags(result?.data?.data?.items);
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );

  const onDrop = useCallback((acceptedFiles) => {
    // Filter out .zip files
    const filteredFiles = acceptedFiles.filter(file => !file.name.endsWith('.zip'));
    const rejectedFiles = acceptedFiles.filter(file => file.name.endsWith('.zip'));
  
    // Handle rejected files (optional)
    if (rejectedFiles?.length > 0) {
      showErrorToast("ZIP files are not allowed.");
    }
  
    // Ensure only one file is accepted and check file size
    if (filteredFiles?.length > 0) {
      const fileToUpload = filteredFiles[0];
      if (fileToUpload.size > 1000000000) { // 1GB limit
        showErrorToast("Maximum file size for uploads is 1GB.");
      } else {
        setFile(fileToUpload);
      
      }
    }
  }, [setFile, showErrorToast]);
  // const onDrop = useCallback((acceptedFiles) => {
  //   // Ensure only one file is accepted
  //   if (acceptedFiles.length > 0) {
  //     if (acceptedFiles?.[0]?.size > 1000000000) {
  //       showErrorToast("Maximum file size for uploads is 1GB.");
  //     } else {
  //       setFile(
  //         acceptedFiles[0]
  //         //  name: acceptedFiles[0].name,
  //         // parentId:clickedFolderZus.id
  //       );
  //     }
  //   }
  // }, []);
  const acceptedFileTypes = [".zip"];
  const onZipfileDrop = useCallback((acceptedFiles) => {
    const zipFiles = acceptedFiles.filter((file) =>
      acceptedFileTypes.some((type) => file.name.endsWith(type))
    );

    if (zipFiles.length > 0) {
      if (zipFiles?.[0]?.size > 1000000000) {
        showErrorToast("Maximum file size for uploads is 1GB.");
      } else {
        setZipFile(zipFiles[0]);
      }
    }
  }, []);

  const removeFile = () => {
    setFile(null);
    if (block == "UploadZip") {
      setZipFile(null);
    }
  };

  const handleUpload = () => {
    if (block == "File") {
      const metadataPayload = metadata?.map((val) => {
        delete val?.idx;
        return val;
      });

      metadataPayload?.forEach((obj) => {
        // Check if the type is "date"
        if (obj.type === "date") {
          // Modify the data key using Moment.js to remove the time part
          if(obj?.data){
            obj.data = CustomMoment(obj?.data)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD");
           }
        }
      });

     

      let payloadFile = {
        parentId: clickedFolderZusNon?.id,
        Filedata: file,
        newFilename: rename,
        metadata: JSON.stringify({ values: metadataPayload }),
        content: JSON.stringify({ tags: tags }),
      };
    
      
      uploadSingleAssetFileMutation.mutate(payloadFile);
    }

    if (block == "WebUrl") {
      const metadataPayload = metadata?.map((val) => {
        delete val?.idx;
        return val;
      });
      metadataPayload?.forEach((obj) => {
        // Check if the type is "date"
        if (obj.type === "date") {
          // Modify the data key using Moment.js to remove the time part
          if(obj?.data){
            obj.data = CustomMoment(obj?.data)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD");
           }
        }
      });
      let payloadURL = {
        parentId: clickedFolderZusNon?.id,
        fileUrl: webUrlData,
        newFilename: rename,
        metadata: JSON.stringify({ values: metadataPayload }),
        content: JSON.stringify({ tags: tags }),
      };
      uploadFromUrlMutation.mutate(payloadURL);
    }

    if (block == "UploadZip") {
      let payloadZip = {
        parentId: clickedFolderZusNon?.id,
        Filedata: zipFile,
      };
      uploadZipMutation.mutate(payloadZip);
    }
  };
  const handleUploadFileModal = () => {
    setIsUploadFileModal(true);
    getCollectionModalTagsMutation.mutate();
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    setWebUrlData(data?.webUrl);
    let payload = {
      url: data?.webUrl,
      parentId: searchParams?.get("folderId"),
    };
    // if (searchParams?.get("folderId")) {
    setIsUploadFileModal(true);
    getCollectionModalTagsMutation.mutate();
    // uploadFromUrlMutation.mutate(payload);
    // }
  };
  const handleNavigationBack = () => {
    navigate(-1);
  };
  const handleCloseModal = () => {
    setIsUploadFileModal(false);
    setIsAddfolderModal(false);
    setIsRenameModal(false);
    setIsDeletefolderModal(false);
  };
  // const handleCallbackUploadFileModal = (paramsDataUploadModal) => {

  //   let payloadFile = {
  //     parentId: searchParams?.get("folderId"),
  //     Filedata: file,
  //     content: { tags: paramsDataUploadModal?.content },
  //     newFilename: paramsDataUploadModal?.newFilename,
  //   };
  //   let payloadZip = {
  //     parentId: searchParams?.get("folderId"),
  //     Filedata: zipFile,
  //   };

  //   let payloadWebUrl = {
  //     url: webUrlData,
  //     parentId: searchParams?.get("folderId"),
  //     content: { tags: paramsDataUploadModal?.content },
  //     newFilename: paramsDataUploadModal?.newFilename,
  //   };
  //   if (block == "File") {

  //     uploadSingleAssetFileMutation.mutate(JSON.stringify(payloadFile));
  //   }

  //   if (block == "UploadZip") {
  //     uploadZipMutation.mutate(payloadZip);
  //   }
  //   if (block == "WebUrl") {
  //     uploadFromUrlMutation.mutate(JSON.stringify(payloadWebUrl));
  //   }

  // };

  const handleInputChange = (val, item, index, key) => {
    if (key === "name" && val.trim() === "") {
      return; // Exit the function without updating the state
    }
    let updatedItem = { ...item, [key]: val };

    let res = [...metadata];
    if (key != "delete") {
      res.splice(index, 1, updatedItem);
      setMetaData(res);
    } else {
      res.splice(index, 1);

      setMetaData(res);
    }
  };

  const [selectedValuesTagsModal, setSelectedValuesTagsModal] = useState();
  const [paramsDataUploadModal, setParamsDataUploadModal] = useState();

  const [inputValueUploadFileModal, setInputValueUploadFileModal] = useState();

  const handleChangeTags = (selectedOptions) => {
    setTags(selectedOptions);
  };

  const handleOnChangeTextField = (value) => {
    setInputValueUploadFileModal(value);

    let data = {
      content: selectedValuesTagsModal,
      newFilename: value,
    };

    setParamsDataUploadModal(data);
  };

  const handleSetBlock = (blockName) => {
    if (!isUploadFileModal) {
      setBlock(blockName);
    }
  };

  const handleClearFileUpload = () => {
    removeFile();
    setIsUploadFileModal(false);
  };

  const handleChangeNewMetaData = (inputValue, field) => {
    setNewMetaData({ ...newMetaData, [field]: inputValue });

    if (field === "language") {
      setNewMetaData({ ...newMetaData, [field]: inputValue?.value });
    }
    if (field === "type") {
      setNewMetaData({ ...newMetaData, [field]: inputValue?.value });
    }
    // setNewMetaData({...newMetaData, [field]: inputValue})
  };

  const handleAddMetadata = () => {
    if (!newMetaData?.name) {
      setNameError(true)
      // Display an error message or take appropriate action
    
      return; // Exit the function if the Name field is empty
    }
  
    setNameError(false);
    setMetaData([...metadata, newMetaData]);
    
  };
 
  const handleAddFolderModal = () => {
    // setAssetId(id);
    setIsAddfolderModal(true);
  };

  const handleChangeMetaData = (inputValue, obj, field) => {
    let res = metadata?.map((val, ind) => {
      if (val?.idx === obj?.idx) {
        return { ...val, [field]: inputValue };
      } else {
        return val;
      }
    });
    setMetaData(res);
  };

  const handleRemoveMetaData = (obj) => {
    let res = metadata?.filter((val) => val?.idx !== obj?.idx);
    setMetaData(res);
  };
  const handleClickMime = (event, item) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleCloseMenu = (item) => {
    setAnchorEl(null);
    setAnchorEl1(null);
  };
  const handleRenameModal = () => {
    // setAssetId(id);
    setIsRenameModal(true);
  };
  const handleDeleteFolderModal = () => {
    // setAssetId(id);
    setIsDeletefolderModal(true);
  };
  useEffect(() => {
    deleteFolderSuccess && handleCallbackDeleteFolder()
     }, [deleteFolderSuccess]);

  const handleCallbackDeleteFolder = () => {
    
    let payload = {
      folderName: "",
      folderId: clickedFolderZusNon?.id,
      mimetype: selectedMimeTypeZusNon?.mimetype,
      sortBy: sortingZusNon && sortingZusNon[0],
      sortOrder: sortingZusNon && sortingZusNon[1],
    };
    mutation.mutate(payload);
  };

  const isValidUpdateThruURL = () => {
    if (
      !rename &&
      (metadata === null ||
        metadata === undefined ||
        (Array.isArray(metadata) && metadata?.length === 0)) &&
      (tags === null ||
        tags === undefined ||
        (Array.isArray(tags) &&
        tags?.length === 0))
    ) {
      return false;
    }

    return true;
  };

  
  const handleCloseMime = (item) => {
    // let payload={
    //   mimetype:item?.value

    // }
    if (item?.value == "creat") {
      handleAddFolderModal();
    }
    if (item?.value == "update") {
      handleRenameModal();
    }
    if (item?.value == "dele") {
      handleDeleteFolderModal();
    }
    // setFiltersZustand(payload )
    // setSelectedMimeTypeZusNon(payload)

    setAnchorEl1(null);
    //  mutation.mutate(payload)
  };

  const shouldButtonDisappear =
    !clickedFolderZusNon?.permissions?.delete_asset &&
    !clickedFolderZusNon?.permissions?.create_asset &&
    !clickedFolderZusNon?.permissions?.edit_asset;
  return (
    <>
      {/* {uploadFromUrlMutation?.isLoading && <Loader></Loader>}
      {uploadZipMutation?.isLoading && <Loader></Loader>}
      {uploadSingleAssetFileMutation?.isLoading && <Loader></Loader>}{" "} */}
      {(mutation.isLoading ) && <Loader></Loader>}
      {(uploadSingleAssetFileMutation.isLoading) && <Loader isDrawerOpen={true}></Loader>}
      <Box>
        <DamPortalModal
          open={isAddfolderModal}
          onClose={handleCloseModal}
          titleMessage={"Create folder"}
          // // node={selectedNode}
          isAddfolderModal={isAddfolderModal}
          manageFolderId={clickedFolderZusNon?.id}
          manageFolderLabel={clickedFolderZusNon?.label}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isRenameModal}
          onClose={handleCloseModal}
          titleMessage={"Rename folder"}
          // // node={selectedNode}
          isRenameModal={isRenameModal}
          manageFolderId={clickedFolderZusNon?.id}
          manageFolderLabel={clickedFolderZusNon?.label}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isDeletefolderModal}
          onClose={handleCloseModal}
          titleMessage={"Delete folder"}
          // // node={selectedNode}
          isDeletefolderModal={isDeletefolderModal}
          manageFolderId={clickedFolderZusNon?.id}
          manageFolderLabel={clickedFolderZusNon?.label}
          handleCallbackDeleteFolder={handleCallbackDeleteFolder}
          deleteFolderSuccess={deleteFolderSuccess}
          setDeleteFolderSuccess={setDeleteFolderSuccess}
        />
      </Box>
      <Box className="buttonGroup">
        {!shouldButtonDisappear && (
          <Button
            onClick={(e) => {
              handleClickMime(e, manageforlderCruDArray);
            }}
            size="small"
            aria-controls={open1 ? "assetType" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            startIcon={<ManagesFolder />}
            endIcon={<DownArrow />}
            // className={`menuButton ${item.menuId===clickedMenu ? "active":""}`}
            className="menuButton select"
          >
            Manage folder
          </Button>
        )}
        <Menu
          anchorEl={anchorEl1}
          id="assetType"
          open={open1}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          disableScrollLock={true}
          {...paperPropsStyles}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <>
            {manageforlderCruDArray?.map((itm, indx) =>
              itm?.label === "Delete folder" &&
              clickedFolderZusNon?.permissions?.delete_asset ? (
                <>
                  <MenuItem onClick={() => handleCloseMime(itm)}>
                    <>
                      {itm?.svg}
                      {itm?.label}
                    </>
                  </MenuItem>
                </>
              ) : itm?.label === "Create folder" &&
                clickedFolderZusNon?.permissions?.create_asset ? (
                <>
                  <MenuItem onClick={() => handleCloseMime(itm)}>
                    <>
                      {itm?.svg}
                      {itm?.label}
                    </>
                  </MenuItem>
                </>
              ) : itm?.label === "Rename folder" &&
                clickedFolderZusNon?.permissions?.edit_asset ? (
                <>
                  <MenuItem onClick={() => handleCloseMime(itm)}>
                    <>
                      {itm?.svg}
                      {itm?.label}
                    </>
                  </MenuItem>
                </>
              ) : (
                <></>
              )
            )}
          </>
        </Menu>
        {/* <Button
          size="small"
          startIcon={<ManageFolder />}
          className="button"
          onClick={() => {
            handleNavigationFolder();
          }}
        >
          Manage folderssssstest
        </Button> */}
     
        {clickedFolderZusNon?.permissions?.create_asset && (
          <Button
            size="small"
            startIcon={<UploadAsset />}
            className="button"
            variant="filled"
            // onClick={() => {
            //   handleNavigation();
            // }}
            onKeyDown={handleKeyDown} 
            onClick={() => toggleDrawer(true)}
            disabled={!clickedFolderZusNon}
          >
            Upload asset
          </Button>
        )}
      </Box>
      <Drawer
        open={open}
        // onClose={toggleDrawer(false)}
        anchor="right"
        className="uploadAsset"
      >
        <Typography component="h5" variant="h5" className="drawertitle">
          <UploadAsset height={25} />{" "}
          <span className="marginL-xs">Upload assets</span>
          <Close className="closed" onClick={() => toggleDrawer(false)} />
        </Typography>

        <Box>
          <FormGroup>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="File"
                name="radio-buttons-group"
                className="tabView"
              >
                <FormControlLabel
                  value="File"
                  control={<Radio />}
                  label="File"
                  className="uploadAsset"
                  disabled={isUploadFileModal}
                  checked={block === "File"}
                  onClick={() => handleSetBlock("File")}
                />
                <FormControlLabel
                  value="WebUrl"
                  control={<Radio />}
                  label="Web Url"
                  className="uploadAsset"
                  disabled={isUploadFileModal}
                  checked={block === "WebUrl"}
                  onClick={() => handleSetBlock("WebUrl")}
                />
                <FormControlLabel
                  value="UploadZip"
                  control={<Radio />}
                  label="Upload Zip"
                  disabled={isUploadFileModal}
                  className="uploadAsset"
                  checked={block === "UploadZip"}
                  onClick={() => handleSetBlock("UploadZip")}
                />
              </RadioGroup>
            </FormControl>
          </FormGroup>

          {/* <Box>
        <DamPortalModal
          open={isUploadFileModal}
          onClose={handleCloseModal}
          titleMessage={
            block === "File" ? "Details" : block === "WebUrl" ? "Details" : ""
          }
          block={block}
          uploadFileTags={uploadFileTags}
          // collectionName={collectionName}
          isUploadFileModal={isUploadFileModal}
          handleCallbackUploadFileModal={handleCallbackUploadFileModal}
        />
      </Box> */}

          {block === "File" &&
            (!isUploadFileModal ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "1em",
                    cursor: "pointer",
                  }}
                >
                  <Dropzone onDrop={onDrop} maxFiles={1}>
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <Box
                          {...getRootProps()}
                          // style={dropzoneStyle}
                          sx={{
                            border: "1px dashed var(--border)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "1em 0",
                            width: "100%",
                            minHeight: "300px",
                            boxSizing: "border-box",
                            flexDirection: "column",
                            fontSize: "var(--fontSizeM)",
                          }}
                        >
                          <input {...getInputProps()} />
                          <p>Drag 'n' drop a file here </p>
                          <p className="padding-sm">OR</p>
                          <p className="button link">Click to select a file</p>
                        </Box>

                      
                        {file && Object.keys(file)?.length != 0 && (
                          <Box className="drawerFooter">
                            {handleUploadFileModal()}
                          </Box>
                        )}
                      </>
                    )}
                  </Dropzone>
                </Box>
                {/* {file && Object.keys(file)?.length != 0 && (
                  <Box className="drawerFooter">
                    <Button
                      className="button"
                      variant="filled"
                      onClick={handleUploadFileModal}
                    >
                      Next
                    
                    </Button>
                  </Box>
                )} */}
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "1em 1em",
                    width: "100%",
                  }}
                >
                  <form className="form">
                    <Box className="dataBox" sx={{ marginTop: "1em" }}>
                      <Typography variant="h6">Selected file</Typography>
                      <Box className="formBox1">
                        <Typography className="flex" mr={2}>
                       { file?.type?.includes("image") ?  <img
                            src={URL?.createObjectURL(
                              new Blob([file], { type: "application/zip" })
                            )}
                            alt="image"
                            width="50px"
                            height="50px"
                          /> : <Noimage height={30}/>}

                          {file?.name}
                        </Typography>

                        <Cross
                          onClick={() => handleClearFileUpload()}
                          cursor={"pointer"}
                        />
                      </Box>
                    </Box>

                    <FormControl variant="standard" sx={{ marginTop: "1em" }}>
                      <label>Rename</label>
                      <TextField
                        id="outlined-basic"
                        label=""
                        value={rename}
                        onChange={(e) => setRename(e.target.value)}
                        // onKeyDown={handleKeyPress}
                        variant="standard"
                      />
                    </FormControl>
                    <FormControl variant="standard" sx={{ marginTop: "1em" }}>
                      <label>Tags</label>
                      <ReactSelectt
                        isMulti
                        options={uploadFileTags || []}
                        value={tags}
                        onChange={handleChangeTags}
                        className="react-select"
                        menuPlacement="auto"
                      />
                    </FormControl>

                    <Box
                      className="dataBox"
                      sx={{ marginTop: "1em", marginBottom: "150px" }}
                    >
                      <Typography variant="h6">Metadata</Typography>
                      <FormControl variant="standard" className="formBox">
                        <input
                          type="text"
                          placeholder="Name"
                          value={newMetaData?.name}
                          onChange={(e) =>
                            handleChangeNewMetaData(e.target.value, "name")
                          }
                          className="textbox"
                        />
                        <ReactSelectt
                          // labelId="demo-simple-select-label"
                          // id="demo-simple-select"
                          options={inputOptions || []}
                          className="selection"
                          styles={customStyles}
                          placeholder="Input"
                          menuPlacement="auto"
                          onChange={(e) => handleChangeNewMetaData(e, "type")}
                          // value={newMetaData?.type}
                        />

                        <ReactSelectt
                          options={languageOptionsZus || []}
                          className="selection"
                          styles={customStyles}
                          placeholder="Language"
                          menuPlacement="auto"
                          onChange={(e) =>
                            handleChangeNewMetaData(e, "language")
                          }
                        />

                        <Button
                          size="small"
                          variant="filled"
                          onClick={() => handleAddMetadata()}
                          className="button"
                        >
                          Add
                        </Button>
                      </FormControl>
                      {nameError && (
                        <Box className="error-message">
                        { commonMessages?.namefieldErrorMsg}
                        </Box>
                      )}
                      <TableContainer className="tableBox">
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component="th" width="25%">
                                Name
                              </TableCell>
                              <TableCell component="th" width="25%">
                                Language
                              </TableCell>
                              <TableCell component="th" width="25%">
                                Value
                              </TableCell>
                              <TableCell
                                component="th"
                                align="center"
                                width="25%"
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {metadata?.map((value, ind) => (
                              <TableRow tabIndex={-1}>
                                <TableCell>
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    className="textbox"
                                    value={value?.name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e.target.value,
                                        value,
                                        ind,
                                        "name"
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <ReactSelectt
                                    defaultValue={
                                      value?.language == "en"
                                        ? { label: "en", value: "en" }
                                        : value?.language == "de"
                                        ? { label: "de", value: "de" }
                                        : value?.language == "fr"
                                        ? { label: "fr", value: "fr" }
                                        : { label: "en", value: "en" }
                                    }
                                    //  className="select"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e?.value,
                                        value,
                                        ind,
                                        "language"
                                      )
                                    }
                                    styles={customStyles}
                                    // menuIsOpen={true}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    options={languageOptionsZus || []}
                                    // value={targetObjectFolder?.assignedTags}
                                  />
                                </TableCell>
                                <TableCell>
                                  <>
                                    {" "}
                                    {value?.type == "check" ? (
                                      <>
                                        <Checkbox
                                          // value={item.data}
                                          // isChecked={true}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.checked,
                                              value,
                                              ind,
                                              "data"
                                            )
                                          }
                                        />
                                      </>
                                    ) : value?.type == "area" ? (
                                      <>
                                        <TextareaAutosize
                                          // size="md"
                                          // name="Size"
                                          maxLength={200}
                                          style={{
                                              
                                            maxHeight: '220px',   // Limit the maximum height
                                            overflowY: 'auto',    // Add scrollbars if content exceeds max-height
                                            resize: 'vertical',       // Disable manual resizing
                                          }}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e.target.value,
                                              value,
                                              ind,
                                              "data"
                                            )
                                          }
                                        />
                                      </>
                                    ) : value?.type == "date" ? (
                                      <>
                                        <DatePicker
                                          showIcon
                                          toggleCalendarOnIconClick
                                          onFocus={(e) =>
                                            (e.target.readOnly = true)
                                          }
                                          onChange={(date) =>
                                            handleInputChange(
                                              date,
                                              value,
                                              ind,
                                              "data"
                                            )
                                          }
                                          selected={value?.data}
                                          inputMode="none"
                                          showYearDropdown
                                          yearDropdownItemNumber={60}
                                          scrollableYearDropdown
                                        />
                                      </>
                                    ) : (
                                      <input
                                        type="text"
                                        className="textbox"
                                        value={value?.data}
                                        maxLength={50}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            value,
                                            ind,
                                            "data"
                                          )
                                        }
                                      />
                                    )}
                                  </>
                                </TableCell>
                                <TableCell>
                                  <TooltipStyle
                                    placement="bottom"
                                    arrow
                                    title="Close"
                                  >
                                    <Button
                                      size="small"
                                      className="button icon"
                                      onClick={(e) => {
                                        handleInputChange(
                                          e.target.value,
                                          value,
                                          ind,
                                          "delete"
                                        );
                                      }}
                                    >
                                      <Close className="small" />
                                    </Button>
                                  </TooltipStyle>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </form>
                </Box>
                <Box className="drawerFooter">
                  <Button
                    className="button"
                    type="submit"
                    variant="filled"
                    onClick={() => handleUpload()}
                    disabled={uploadSingleAssetFileMutation?.isLoading}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            ))}

          {block === "WebUrl" &&
            (!isUploadFileModal ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "1em 1em",
                  width: "100%",
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                  <input
                    type="text"
                    className="textbox"
                    // value={email}
                    placeholder="Enter url to upload asset"
                    name="webUrl"
                    // onChange={handleEmailChange}
                    {...register("webUrl")}
                  ></input>
                  {errors?.webUrl && (
                    <ErrorMessageStyled>
                      {errors.webUrl?.message}
                    </ErrorMessageStyled>
                  )}

                  <Box className="drawerFooter drawerFooterM">
                    <Button
                      disabled={!isValid}
                      className="button"
                      type="submit"
                      variant="filled"
                    >
                      Next
                    </Button>
                  </Box>
                </form>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "1em 1em",
                  width: "100%",
                }}
              >
                <form className="form">
                  <Box className="dataBox" sx={{ marginTop: "1em" }}>
                    <Typography variant="h6">Selected file</Typography>
                    <Box className="formBox1">
                      <Typography className="flex" mr={2}>
                      { webUrlData ?  <img
                          src={webUrlData}
                          alt="image"
                          width="50px"
                          height="50px"
                        /> : <Noimage height={30}/>}
                      </Typography>

                      <Cross
                        onClick={() => handleClearFileUpload()}
                        cursor={"pointer"}
                      />
                    </Box>
                  </Box>
                  <Box className="notification">
                    To upload an asset, please fill out one of the following
                    fields and submit.
                  </Box>
                  <FormControl variant="standard" sx={{ marginTop: "1em" }}>
                    <label>Rename</label>
                    <TextField
                      id="outlined-basic"
                      label=""
                      value={rename}
                      onChange={(e) => setRename(e.target.value)}
                      // onKeyDown={handleKeyPress}
                      variant="standard"
                    />
                  </FormControl>
                  <FormControl variant="standard" sx={{ marginTop: "1em" }}>
                    <label>Tags</label>
                    <ReactSelectt
                      isMulti
                      options={uploadFileTags || []}
                      value={tags}
                      onChange={handleChangeTags}
                      className="react-select"
                      menuPlacement="auto"
                    />
                  </FormControl>

                  <Box
                    className="dataBox"
                    sx={{ marginTop: "1em", marginBottom: "150px" }}
                  >
                    <Typography variant="h6">Metadata</Typography>
                    <FormControl variant="standard" className="formBox">
                      <input
                        type="text"
                        placeholder="Name"
                        value={newMetaData?.name}
                        onChange={(e) =>
                          handleChangeNewMetaData(e.target.value, "name")
                        }
                        className="textbox"
                      />

                      <ReactSelectt
                        // labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        options={languageOptionsZus || []}
                        className="selection"
                        styles={customStyles}
                        // menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="Language"
                        // value={newMetaData?.language}
                        onChange={(e) => handleChangeNewMetaData(e, "language")}
                      />

                      <ReactSelectt
                        // labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        options={inputOptions || []}
                        className="selection"
                        styles={customStyles}
                        placeholder="Input"
                        onChange={(e) => handleChangeNewMetaData(e, "type")}
                        // value={newMetaData?.type}
                        menuPlacement="auto"
                      />

                      <Button
                        size="small"
                        variant="filled"
                        onClick={() => handleAddMetadata()}
                        className="button"
                      >
                        Add
                      </Button>
                    </FormControl>
                    {nameError && (
                      <Box className="error-message">
                   {  commonMessages?.namefieldErrorMsg}
                      </Box>
                    )}
                    <TableContainer className="tableBox">
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        className="table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell component="th" width="30%">
                              Name
                            </TableCell>
                            <TableCell component="th" width="30%">
                              Language
                            </TableCell>
                            <TableCell component="th" width="30%">
                              Value
                            </TableCell>
                            <TableCell
                              component="th"
                              align="center"
                              width="10%"
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {metadata?.map((value, ind) => (
                            <TableRow tabIndex={-1}>
                              <TableCell>
                                <input
                                  type="text"
                                  placeholder="Name"
                                  className="textbox"
                                  value={value?.name}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      value,
                                      ind,
                                      "name"
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <ReactSelectt
                                  defaultValue={
                                    value?.language == "en"
                                      ? { label: "en", value: "en" }
                                      : value?.language == "de"
                                      ? { label: "de", value: "de" }
                                      : value?.language == "fr"
                                      ? { label: "fr", value: "fr" }
                                      : { label: "en", value: "en" }
                                  }
                                  //  className="select"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e?.value,
                                      value,
                                      ind,
                                      "language"
                                    )
                                  }
                                  styles={customStyles}
                                  // menuIsOpen={true}
                                  menuPlacement="auto"
                                  menuPosition="fixed"
                                  options={languageOptionsZus || []}
                                  // value={targetObjectFolder?.assignedTags}
                                />
                                {/* <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={value?.language}
                                >
                                  <MenuItem value={""}></MenuItem>
                                </Select> */}
                              </TableCell>
                              <TableCell>
                                <>
                                  {" "}
                                  {value?.type == "check" ? (
                                    <>
                                      <Checkbox
                                        // value={item.data}
                                        // isChecked={true}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.checked,
                                            value,
                                            ind,
                                            "data"
                                          )
                                        }
                                      />
                                    </>
                                  ) : value?.type == "area" ? (
                                    <>
                                      <TextareaAutosize
                                        // size="md"
                                        // name="Size"
                                        maxLength={200}
                                        style={{
                                            
                                          maxHeight: '220px',   // Limit the maximum height
                                          overflowY: 'auto',    // Add scrollbars if content exceeds max-height
                                          resize: 'vertical',       // Disable manual resizing
                                        }}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            value,
                                            ind,
                                            "data"
                                          )
                                        }
                                      />
                                    </>
                                  ) : value?.type == "date" ? (
                                    <>
                                      <DatePicker
                                        showIcon
                                        onFocus={(e) =>
                                          (e.target.readOnly = true)
                                        }
                                        onChange={(date) =>
                                          handleInputChange(
                                            date,
                                            value,
                                            ind,
                                            "data"
                                          )
                                        }
                                        selected={value?.data}
                                        inputMode="none"
                                        showYearDropdown
                                        yearDropdownItemNumber={60}
                                        scrollableYearDropdown
                                      />
                                    </>
                                  ) : (
                                    <input
                                      type="text"
                                      className="textbox"
                                      value={value?.data}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          value,
                                          ind,
                                          "data"
                                        )
                                      }
                                    />
                                  )}
                                </>
                              </TableCell>
                              {/* <TableCell>
                                <input
                                  type="text"
                                  placeholder="Value"
                                  className="textbox"
                                  value={value?.data}
                                  onChange={(e) =>
                                    handleChangeMetaData(
                                      e.target.value,
                                      value,
                                      "data"
                                    )
                                  }
                                />
                              </TableCell> */}
                              <TableCell>
                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Close"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    onClick={(e) => {
                                      handleInputChange(
                                        e.target.value,
                                        value,
                                        ind,
                                        "delete"
                                      );
                                    }}
                                  >
                                    <Close className="small" />
                                  </Button>
                                </TooltipStyle>
                              </TableCell>
                              {/* <TableCell align="center">
                                <Cross
                                  cursor={"pointer"}
                                  onClick={() => handleRemoveMetaData(value)}
                                />
                              </TableCell> */}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </form>
                <Box className="drawerFooter drawerFooterM">
                  <Button
                    className="button"
                    type="submit"
                    disabled={!isValidUpdateThruURL() ||  uploadFromUrlMutation?.isLoading}
                    variant="filled"
                    onClick={() => handleUpload()}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            ))}

          {block === "UploadZip" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "1em",
                  cursor: "pointer",
                }}
              >
                <Dropzone onDrop={onZipfileDrop} maxFiles={1}>
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <Box
                        {...getRootProps()}
                        // style={dropzoneStyle}
                        sx={{
                          border: "1px dashed var(--border)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "1em 0",
                          width: "100%",
                          minHeight: "300px",
                          boxSizing: "border-box",
                          flexDirection: "column",
                          fontSize: "var(--fontSizeM)",
                        }}
                      >
                        <input
                          {...getInputProps({ accept: acceptedFileTypes })}
                        />
                        <p>Drag 'n' drop a file here </p>
                        <p className="padding-sm">OR</p>
                        <p className="button link">
                          Click to select a Zip file
                        </p>
                      </Box>
                      {zipFile && (
                        <Box
                          className="dataBox col-12"
                          sx={{ marginTop: "1em" }}
                        >
                          <Typography variant="h6">Selected file</Typography>
                          <Box className="formBox1">
                            <Typography className="flex" mr={2}>
                              {zipFile?.name}
                            </Typography>

                            <Cross
                              onClick={() => handleClearFileUpload()}
                              cursor={"pointer"}
                            />
                          </Box>
                        </Box>
                      )}

                      {/* {zipFile && (
                        <Box className="filePath">
                          <p>{zipFile?.name}</p>
                          <Button
                            className="button icon"
                            startIcon={<Close />}
                            onClick={removeFile}
                          ></Button>
                        </Box>
                      )} */}
                    </>
                  )}
                </Dropzone>
              </Box>
              {zipFile && Object.keys(zipFile)?.length != 0 && (
                <Box className="drawerFooter">
                  <Button
                    className="button"
                    variant="filled"
                    onClick={handleUpload}

                 disabled={uploadZipMutation?.isLoading}   
                  >
                    Upload Zip
                  </Button>
                </Box>
              )}
            </>
          ) : null}
        </Box>
      </Drawer>
    </>
  );
};

export default Selector;
