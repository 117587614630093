import { Box, Button, Menu, MenuItem, ButtonGroup, Card, CardActions, CardContent, CardMedia, Checkbox, Chip, FormControlLabel, Grid, InputLabel, Pagination, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Tooltip, Drawer } from "@mui/material";
import { useLocation, useNavigate,useSearchParams } from "react-router-dom";
import {ReactComponent as Share} from '../assest/svg/shareicon.svg';
import {ReactComponent as Media} from '../assest/svg/mediaicon.svg';
import {ReactComponent as Shop} from '../assest/svg/shopicon.svg';
import {ReactComponent as Download} from '../assest/svg/downloadicon.svg';
import {ReactComponent as Edit} from '../assest/svg/editicon.svg';
import {ReactComponent as Move} from '../assest/svg/moveicon.svg';
import {ReactComponent as Delete} from '../assest/svg/deleteicon.svg';
import {ReactComponent as Sort} from '../assest/svg/sort.svg';
import { ReactComponent as Close } from "../assest/svg/cross.svg";
import { ReactComponent as Closed } from "../assest/svg/close.svg";
import { ReactComponent as RemoveFilter } from "../assest/svg/removeFilter.svg";
import { ReactComponent as DeleteFilter } from "../assest/svg/deleteFilter.svg";
import { ReactComponent as SaveFilter } from "../assest/svg/saveFilter.svg";

import { ReactComponent as SaveAsNew } from "../assest/svg/saveAsFilter.svg";




import {ReactComponent as DownArrow} from '../assest/svg/chevronDown.svg';
import {ReactComponent as NoFolderSelectedSvg} from '../assest/svg/nofolderSelected.svg';
import  NoFolderSelected from '../assest/images/NoFolderSelectedd.png';
import {ReactComponent as Cross} from '../assest/svg/cross.svg';

import { saveAs } from 'file-saver';
import {ReactComponent as CardView} from '../assest/svg/cardview.svg';
import  {useMutation}  from "@tanstack/react-query";
import { menuStyles, paperPropsStyles } from "../styledComponents/MenuStyles"; 
import { menuStylesThreeDots, paperPropsStylesThreeDotsLAndingPAge } from "../styledComponents/MenuStylesThreeDotsCardLandingPage"; 
import DamPortalModal from "./DamPortalModal";
import { useEffect, useMemo, useState } from "react";
import {ReactComponent as ListView} from '../assest/svg/listView.svg';
import PaginationComponent from "./PaginationComponent";
import { addToCart, addToCollection, batchMetaDataUpdate, batchUpdate, customDownloadApi, deleteAsset, deleteSavedFiltersApi, detailPageNavigation, downloadSelectedAssets, getAllFiltersApi, getCollectionModalTags, getFirstPageListing, getUserCart, saveFilterApi } from "../api/postRequest";
import Loader from "./Loader";
import { useStore } from "../store/persistedStore";
import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import {ReactComponent as Noimage} from '../assest/svg/NoImage.svg';
import {ReactComponent as PdfIcon} from '../assest/svg/pdf.svg';
import {ReactComponent as HtmlIcon} from '../assest/svg/html.svg';
import {ReactComponent as PsdIcon} from '../assest/svg/psd.svg';
import {ReactComponent as CsvIcon} from '../assest/svg/csv.svg';
import {ReactComponent as SvgIcon} from '../assest/svg/svg.svg';
import {ReactComponent as TiffIcon} from '../assest/svg/tiff.svg';
import {ReactComponent as EpsIcon} from '../assest/svg/eps.svg';
import { ReactComponent as Mp4Icon } from "../assest/svg/mp4.svg";
import { sortArray } from "../utils/constant";
import { ReactComponent as More } from "../assest/svg/more.svg";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import { getCollectionList } from "../api/getRequest";
import { getHeaderListFilters } from "../api/getRequest";
import SortMenu from "./sortMenu";
import FolderTree, { truncate } from "./FolderTree";
import FolderTreeMoveToFolder, { truncateMove } from "./FolderTreeMoveToFolder";
import { withStyles } from "@material-ui/core/styles";
import CustomMoment from "moment-timezone";
import commonMessages from "../utils/commomMessages";
import moment from "moment";
import usePagination from '../customHooks/usePagination';

export const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);

const RightSectionImages = ({setInternalSearchValue,internalSearchValue}) => {
  const [headerFilterList, setHeaderFilterList] = useState();
  const [selectedValues, setSelectedValues] = useState();
  const [selectedModiDate, setselectedModiDate] = useState();
  const [selectedCreationDate, setselectedCreationDate] = useState();
  const [savePopUpInputBoxVAlue, setSavePopUpInputBoxVAlue] = useState();
  const [selectedValueType, setSelectedValueType] = useState();
  const [selectedValueTypeMime, setSelectedValueTypeMime] = useState();
  const [totalGetFilterData, setTotalGetFilterData] = useState();
  const [objectIsEqual, setObjectIsEqual] = useState();

  const [deleteFilterChip, setDeleteFilterChip] = useState();

  
  
  const [filterLabelValue, setFilterLabelValue] = useState();
  const [savedFilterDropdownSelectedLocal, setSavedFilterDropdownSelectedLocal] = useState();
  const[searchParams]=useSearchParams();
  const searchedItem = searchParams.get("id")
  const mutationFilter = useMutation((post) => getHeaderListFilters(post), {
    mutationKey: "getHeaderListFiltersI",
    method: "GET",
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          setHeaderFilterList(result.data?.data);
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  useEffect(() => {
    mutationFilter.mutate();
  }, []);
  const {
    setMainPageListingArr,
    mainPageListingArr,
    setTotalCountZustand,
    totalCountZustand,
    filtersZustand,
    setFiltersZustand,
    clickedFolderZus,
    setClickedFolderZus,
    detailPageRowObjectDataZus,
    setdetailPageRowObjectDataZus,
  } = useStore();
  const {
    clickedFolderZusNon,

    selectedMimeTypeZusNon,
    collectionListZusNon,
    setUserCartListingZusNon,    setUserCartListingTotalCountZusNon,userCartListTotalCountZusNon,
    setClickedMoveToFolderPopUpZusNon,
    setCollectionListZusNon,
    setSortingZusNon,
    sortingZusNon,
    selectedFilterZusNon,
    setSelectedFilterZusNon,setApplyFilterBtnSavedFilterZusNon,
    setFilterMasalaZusNon,
    filterMasalaZusNon,
    setIsNewFilterOpenZusNon,
    isNewFilterOpenZusNon,applyFilterBtnSavedFilterZusNon,applyFilterBtnCreateNewZusNon,loaderZusNon,setSearchValueZusNon,payloadForSortZusNon,setPayloadForSortZusNon
  } = useNonPersistedStore();

  const navigate = useNavigate();

  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElOption, setAnchorElOption] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [loopMenuPopId, setLoopMenuPopId] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [addToCollectionModal, setAddToCollectionModal] = useState(false);
  const [createNewFilterNameModal, setCreateNewFilterNameModal] = useState(false);
  const [saveAsNewFilterNameModal, setSaveAsNewFilterNameModal] = useState(false);
  const [allFilterValuesEmpty, setAllFilterValuesEmpty] = useState();

  

  

  
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [downloadCustomModal, setDownloadCustomModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [IsMoveModal, setIsMoveModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [bulkEditModal, setBulkEditModal] = useState(false);
  const [bulkEditTags, setBulkEditTags] = useState();
  const [nameError, setNameError] = useState(false);

  const [deleteId, setDeleteId] = useState();
  const [gridView, setIsGridView] = useState(true);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  const [menuOpenItem, setMenuOpenItem] = useState();
  // const [listView, setIsListView] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [assetId, setAssetId] = useState();
  const [detailPageData, setDetailPageData] = useState();
  const [selectedDropdownValue, setSelectedDropdownValue] = useState();
  const [parentandAssetId, setParentandAssetId] = useState();
  const [selectedPosition, setSelectedPosition] = useState();
  const [asset, setAsset] = useState();
  const [optionBox, setOptionBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [newFilterOnlyName, setNewFilterOnlyName] = useState(null);
  
  
  const getSelectedIds = (arr) => {
    let selectedIds = arr?.reduce((acc, curr) => {
      if (curr?.isChecked) {
        acc.push(curr?.id);
      }
      return acc;
    }, []);
    return selectedIds || [];
  };

  const selectedIds = useMemo(
    () => getSelectedIds(mainPageListingArr),
    [mainPageListingArr]
  );


  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(); // Replace with actual total count from your API

  const pageSize = 12; // Set your page size here
  const selectedCount = selectedIds?.length;
  const showSelectedBox = selectedCount > 1 && optionBox;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openOption = Boolean(anchorElOption);
  const handleClickOption = (event, obj) => {
    setMenuOpenItem(obj?.id);
    setLoopMenuPopId(obj);
    setAnchorElOption(event.currentTarget);
  };
  const handleCloseOption = () => {
    setAnchorElOption(null);
    setMenuOpenItem();
  };

  const getAllFiltersApimutation = useMutation(
    (post) => getAllFiltersApi(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            setTotalGetFilterData(result?.data?.data);
            const output = result?.data?.data.reduce((acc, curr) => {
              let obj = { filterName: curr.filterName };
              acc.push(obj);
              return acc;
            }, []);
            const labelValue = output?.map((item) => {
              return {
                label: item?.filterName?.label,
                value: item?.filterName?.value,
              };
            });
          
            const filteredFilter =labelValue &&  labelValue?.find(
              (filter) => filter.label === newFilterOnlyName
            );
          
            if(filteredFilter?.value){
              setSelectedFilterZusNon(filteredFilter);
            }
           

            setFilterLabelValue(labelValue);
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );
  const saveFilterApiMutation = useMutation((post) => saveFilterApi(post), {
    onSuccess: (result,variables) => {
      if (result) {
        if (result.data.success) {
        
          setNewFilterOnlyName(variables?.filter[0]?.filterName)
          getAllFiltersApimutation.mutate();
          showSuccessToast(result?.data?.message);
          setSavePopUpInputBoxVAlue(null)
          setCreateNewFilterNameModal(false);

          handleCloseModal();
        } else {
          showErrorToast(result?.data?.message);
        }
      }
    },
    onError: (error) => {},
  });

  const openSort = Boolean(anchorElSort);
const handleCloseFilter=()=>{
  let payload = {
    currentPage: currentPage,
    folderName: clickedFolderZusNon?.label,
    folderId: clickedFolderZusNon?.id,
    sortBy: sortingZusNon && sortingZusNon[0],
    sortOrder: sortingZusNon && sortingZusNon[1],
  };
  mutation.mutate(payload);
  setSelectedFilterZusNon("");
  setApplyFilterBtnSavedFilterZusNon(false)
  let filterPayload ={
    selectedValueTypeMime: "",
    selectedModiDate: "",
    selectedCreationDate: "",
    selectedValueType:""
  }
  setFilterMasalaZusNon(null)
  setIsNewFilterOpenZusNon(false)
}


const formatModifiedObjectDate = () => {
  if(filterMasalaZusNon?.selectedModiDate){
    const date = new Date(filterMasalaZusNon?.selectedModiDate);


    // Format using Moment.js
    const formattedDate = moment(date).format("YYYY-MM-DD");
  
  
    return (
      <Box className="filterResult">
        <Chip
          label={` From : ${formattedDate}`}
          deleteIcon={<Closed height={15} />}
          onDelete={handleClearSelectedModiDate}
        />
      </Box>
    );
  
  }

 
};
const formatCreationObjectDate = () => {
  if (filterMasalaZusNon?.selectedCreationDate) {
    const date = new Date(filterMasalaZusNon?.selectedCreationDate);

  
    const formattedDate = moment(date).format("YYYY-MM-DD");
  

    return (
      <Box className="filterResult">
        <Chip
          label={` To : ${formattedDate}`}
          deleteIcon={<Closed height={15} />}
          onDelete={handleClearSelectedCreationDate}
        />
      </Box>
    );
  }
};






  const handleCreateNewFilter = () => {
   
   
    setCreateNewFilterNameModal(true)
   
   
   
  };

  const handleSaveAsNewFilter = () => {
   
   
    setSaveAsNewFilterNameModal(true)
   
   
   
  };





const handleClearCompleteFilter = () => {
  

  let payload = {
 
    folderId: location?.pathname?.includes("/searchResultsPage")? "" :clickedFolderZusNon?.id,
  
    sortBy: sortingZusNon && sortingZusNon[0],
    sortOrder: sortingZusNon && sortingZusNon[1],
  };
  setCurrentPage(1)

  mutation.mutate(payload);
  setFilterMasalaZusNon(null);
  setInternalSearchValue("")
  setSearchValueZusNon("")
  setPayloadForSortZusNon("")
};




const handleClearSelectedModiDate = () => {
  const { selectedModiDate, ...newObj } = filterMasalaZusNon;
  setCurrentPage(1)

  let payload = {
    searchTerm:internalSearchValue,
    assetType: newObj?.selectedValueType?.value
      ? newObj?.selectedValueType?.value
      : null,
    mimeType: newObj?.selectedValueTypeMime?.value
      ? newObj?.selectedValueTypeMime?.value
      : null,

    endDate:
      newObj?.selectedCreationDate &&
      CustomMoment(newObj?.selectedCreationDate)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD"),
    folderId:location?.pathname?.includes("/searchResultsPage")? "" : clickedFolderZusNon?.id,
   
    sortBy: sortingZusNon && sortingZusNon[0],
    sortOrder: sortingZusNon && sortingZusNon[1],
  };

  mutation.mutate(payload);
  setFilterMasalaZusNon(newObj);
  setPayloadForSortZusNon({...payloadForSortZusNon,startDate:undefined})
};

const handleClearSelectedCreationDate = () => {
  const { selectedCreationDate, ...newObj } = filterMasalaZusNon;
  setCurrentPage(1)

  let payload = {
    searchTerm:internalSearchValue,
    assetType: newObj?.selectedValueType?.value
      ? newObj?.selectedValueType?.value
      : null,
    mimeType: newObj?.selectedValueTypeMime?.value
      ? newObj?.selectedValueTypeMime?.value
      : null,

    startDate:
      newObj?.selectedModiDate &&
      CustomMoment(newObj?.selectedModiDate)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD"),
    folderId: location?.pathname?.includes("/searchResultsPage")? "" :clickedFolderZusNon?.id,
  
    sortBy: sortingZusNon && sortingZusNon[0],
    sortOrder: sortingZusNon && sortingZusNon[1],
  };

  mutation.mutate(payload);
  setFilterMasalaZusNon(newObj);
  setPayloadForSortZusNon({...payloadForSortZusNon,endDate:undefined})
};

const handleClearSelectedValueType = (e) => {
  const { selectedValueType, ...newObj } = filterMasalaZusNon;
  setCurrentPage(1)
console.log(payloadForSortZusNon,e)
  let payload = {
    searchTerm:internalSearchValue,
    mimeType: newObj?.selectedValueTypeMime?.value
      ? newObj?.selectedValueTypeMime?.value
      : null,
    endDate:
      newObj?.selectedCreationDate &&
      CustomMoment(newObj?.selectedCreationDate)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD"),
    startDate:
      newObj?.selectedModiDate &&
      CustomMoment(newObj?.selectedModiDate)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD"),
    folderId:location?.pathname?.includes("/searchResultsPage")? "" : clickedFolderZusNon?.id,
   
    sortBy: sortingZusNon && sortingZusNon[0],
    sortOrder: sortingZusNon && sortingZusNon[1],
  };

  mutation.mutate(payload);
  setFilterMasalaZusNon(newObj);
  setPayloadForSortZusNon({...payloadForSortZusNon,assetType:undefined})
};


const handleClearSelectedValueTypeMime = () => {
  const { selectedValueTypeMime, ...newObj } = filterMasalaZusNon;
  setCurrentPage(1)

  let payload = {
    searchTerm:internalSearchValue,
    assetType: newObj?.selectedValueType?.value
      ? newObj?.selectedValueType?.value
      : null,
    endDate:
      newObj?.selectedCreationDate &&
      CustomMoment(newObj?.selectedCreationDate)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD"),
    startDate:
      newObj?.selectedModiDate &&
      CustomMoment(newObj?.selectedModiDate)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD"),
    folderId: location?.pathname?.includes("/searchResultsPage")? "" :clickedFolderZusNon?.id,
  
    sortBy: sortingZusNon && sortingZusNon[0],
    sortOrder: sortingZusNon && sortingZusNon[1],
    searchTerm:internalSearchValue
  };

  mutation.mutate(payload);
  setFilterMasalaZusNon(newObj);
  setPayloadForSortZusNon({...payloadForSortZusNon,mimeType:undefined})
};


  useEffect(() => {
    if (clickedFolderZusNon?.id) {
      setIsAllChecked(false);
    }
  }, [clickedFolderZusNon?.id]);

  const mutationGetCollectionList = useMutation(
    (post) => getCollectionList(post),
    {
      mutationKey: "getCollectionList",
      method: "GET",
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            setCollectionListZusNon(result.data?.data?.collectionlist);
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );

  const mutation = useMutation((post) => getFirstPageListing(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          let output = result.data?.data?.items;
          const newArray = output?.map((obj) => ({
            ...obj,
            ["isChecked"]: false,
          }));

          setMainPageListingArr(newArray);
          setImageArray(newArray);
          setTotalCount(result.data?.data?.totalResults);
          setTotalCountZustand(result.data?.data?.totalResults);
        } else {
          // setMainPageListingArr([]);
          // setTotalCount(0);
          // setTotalCountZustand(0);
          showErrorToast(result.data?.message);
        }
      }
    },
    onError: (error) => {
    
      setMainPageListingArr([]);
      setTotalCount(0);
      setTotalCountZustand(0);
      showErrorToast(
        "An error occurred while fetching data. Please try again later."
      );
    },
  });
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize
  })
  const mutationDeleteAsset = useMutation((post) => deleteAsset(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          showSuccessToast(result.data?.message);
          let payload;
          if (selectedIds?.length == mainPageListingArr?.length) {
            payload = {
              currentPage: Math.max(currentPage - 1, 1),  
              folderName: clickedFolderZusNon?.label,
              folderId: location?.pathname?.includes("/searchResultsPage")
                ? ""
                : clickedFolderZusNon?.id,
              mimetype: selectedMimeTypeZusNon?.mimetype,
              searchTerm: location?.pathname?.includes("/searchResultsPage")
              ? searchedItem :internalSearchValue
            };
          } else {
            payload = {
              currentPage: currentPage,
              folderName: clickedFolderZusNon?.label,
              folderId: location?.pathname?.includes("/searchResultsPage")
                ? ""
                : clickedFolderZusNon?.id,
              mimetype: selectedMimeTypeZusNon?.mimetype,
              searchTerm: location?.pathname?.includes("/searchResultsPage")
              ? searchedItem :internalSearchValue
            };
          }
          if(!location?.pathname?.includes("/searchResultsPage"))
          {
            payload ={...payloadForSortZusNon,...payload}
          }
          setIsAllChecked(false);
          mutation.mutate(payload);
          if(selectedIds?.length==mainPageListingArr?.length){
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));  // Ensure page number is at least 1
          }
          mutationGetUserCart.mutate();
          handleCloseModal();
        } else {
        }
      }
    },
    onError: (error) => {},
  });


  const mutationAddToCollection = useMutation(
    (post) => addToCollection(post, selectedDropdownValue),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            showSuccessToast(result.data?.message);
            setAddToCollectionModal(false);
            setSelectedValues(null)
          } else {
            showErrorToast(result.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const mutationDownloadSelected = useMutation(
    (post) => downloadSelectedAssets(post),
    {
      onSuccess: (result) => {
        if (result?.data?.success && result?.data?.url) {
          window.open(`${result?.data?.url}`, "_self");
          setTimeout(() => {
            showSuccessToast(result.data?.message);
          }, 2000);
        } else {
          showErrorToast(result.data?.message);
        }
      },
      onError: (error) => {},
    }
  );
  const getCollectionModalTagsMutation = useMutation(
    (post) => getCollectionModalTags(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            setBulkEditTags(result?.data?.data?.items);
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );
  const customDownloadApiMutation = useMutation(
    (post) => customDownloadApi(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
          
          //   if (  result?.data?.url) {
          //     alert("dfvdfvd")
          //     window.location.href =  "https://dam-api.pimcoredemos.com/api/download-asset/1003";
          // }
            // window.location.href = 'url-to-your-api-endpoint';
            window.open(
              result?.data?.url,
              "_self"
              // "_blank"
            );

            handleCloseModal();
            showSuccessToast(result.data?.message);
          } else {
            showErrorToast(result.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const handleDownloadSelected = () => {
    const selectedItems = mainPageListingArr?.filter(
      (obj) => obj?.isChecked == true
    );
    let selectedIds = [];
    selectedItems.forEach((element) => {
      selectedIds.push(element?.id);
    });
    let payload = { assetIds: selectedIds.toString() };
    mutationDownloadSelected.mutate(payload);
  };

  const handleAddToCart = (obj, structuredData, assetData, customData) => {
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    let payload = {
      assets: [
        {
          dataPoolId: obj?.parent,
          selectedId: obj?.id,
          configs: configArr,
        },
      ],
    };
    mutationAddToCart.mutate(payload);
  };

  useEffect(() => {
    if (addToCartModal === false) {
      setAsset();
    }
  }, [addToCartModal]);
  useEffect(() => {
    if (downloadCustomModal === false) {
      setAsset();
    }
  }, [downloadCustomModal]);

  const handleCallbackAddToCart = (structuredData, assetData, customData) => {
    if (asset) {
      if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
      
        return;
      } else if (
        (!structuredData.type && !assetData.format) ||
        (structuredData.type && !structuredData.format) ||
        (assetData.type && !assetData.format)
      ) {
        // Handle validation error, display message to user or prevent API call
  
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
     
        
        return;
      }
      handleAddToCart(asset, structuredData, assetData, customData);
    } else {
      if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
        return;
      } else if (
        (!structuredData.type && !assetData.format) ||
        (structuredData.type && !structuredData.format) ||
        (assetData.type && !assetData.format)
      ) {
        // Handle validation error, display message to user or prevent API call
  
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
     
        
        return;
      }
      handleBulkAddToCart(structuredData, assetData, customData);
    }
  };
  const hanldleCallbackMoveToFolder = () => {
    handleCloseModal();
  };

  const handleSingleDownload = (obj, structuredData, assetData, customData) => {
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    let payload = {
      assets: [
        {
          dataPoolId: loopMenuPopId?.parent  ? loopMenuPopId?.parent :asset?.parent,
          selectedId: loopMenuPopId?.id   ?loopMenuPopId?.id  : asset?.id,
          configs: configArr,
        },
      ],
      source:"listing"
    };
   
    customDownloadApiMutation.mutate(payload);
  };

  const handleBulkDownload = (structuredData, assetData, customData) => {
    
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    const selectedItems = mainPageListingArr?.reduce((acc, curr) => {
      if (curr?.isChecked === true) {
        acc?.push({
          dataPoolId: curr?.parent,
          selectedId: curr?.id,
          configs: configArr,
        });
      }
      return acc;
    }, []);

    let payload = {
      assets: selectedItems,
      source:"listing"
    };
    customDownloadApiMutation.mutate(payload);
  };
  const handleCallbackDownload = (structuredData, assetData, customData) => {
    if (asset) {
      
      if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
      
        return;
      } else if (
        (!structuredData.type && !assetData.format) ||
        (structuredData.type && !structuredData.format) ||
        (assetData.type && !assetData.format)
      ) {
        // Handle validation error, display message to user or prevent API call
  
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
     
        
        return;
      }
      handleSingleDownload(asset, structuredData, assetData, customData);
    } else {
    
      if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
      
        return;
      } else if (
        (!structuredData.type && !assetData.format) ||
        (structuredData.type && !structuredData.format) ||
        (assetData.type && !assetData.format)
      ) {
        // Handle validation error, display message to user or prevent API call
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
     
        
        return;
      }
      handleBulkDownload(structuredData, assetData, customData);
    }
  };

  const handleBulkAddToCart = (structuredData, assetData, customData) => {
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    
    const selectedItems = mainPageListingArr?.reduce((acc, curr) => {
      if (curr?.isChecked === true) {
        acc?.push({
          dataPoolId: curr?.parent,
          selectedId: curr?.id,
          configs: configArr,
        });
      }
      return acc;
    }, []);

    let payload = {
      assets: selectedItems,
    };
    mutationAddToCart.mutate(payload);
  };

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleCloseSort = (sortObj) => {
    if (sortObj?.value) {
      let wordArray = sortObj?.value && sortObj?.value?.split(" ");

      setSortingZusNon(wordArray);

      let payload = {
        searchTerm: location?.pathname?.includes("/searchResultsPage")? searchedItem : internalSearchValue,
        currentPage: 1,
        sortBy: wordArray && wordArray[0],
        sortOrder: wordArray && wordArray[1],
        folderName: clickedFolderZusNon?.label,
        folderId:location?.pathname?.includes("/searchResultsPage")? "" : clickedFolderZusNon?.id,
      };
      payload ={...payloadForSortZusNon,...payload}
      mutation.mutate(payload);
      setTimeout(() => {
        setIsAllChecked(false)
      }, 1000);
      setAnchorElSort(null);
      setCurrentPage(1);
    } else {
      setAnchorElSort(null);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage === 0) {
      return;
    } else {
      setIsAllChecked(false);
      setCurrentPage(newPage);

      let payload = {
        currentPage: newPage,
        folderName: !location?.state ? clickedFolderZusNon?.label : "",
        folderId:location?.pathname?.includes("/searchResultsPage")? "": !location?.state ?  clickedFolderZusNon?.id :"",
        // mimetype:selectedMimeTypeZusNon?.mimetype
        sortBy: sortingZusNon && sortingZusNon[0],
        sortOrder: sortingZusNon && sortingZusNon[1],
        searchTerm: location?.state
          ? location?.state?.searchTerm
          : internalSearchValue
          ? internalSearchValue
          : "",
          startDate:
          filterMasalaZusNon?.selectedModiDate &&
          CustomMoment(filterMasalaZusNon?.selectedModiDate)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD"),
        endDate:
          filterMasalaZusNon?.selectedCreationDate &&
          CustomMoment(filterMasalaZusNon?.selectedCreationDate)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD"),
        assetType: filterMasalaZusNon?.selectedValueType?.value,
        mimeType: filterMasalaZusNon?.selectedValueTypeMime?.value,
      };
      mutation.mutate(payload);
    }
  };

  const handleAddToCollection = (id, parentId) => {
    if (id && parentId) {
      setParentandAssetId({
        parentId: parentId,
        assetId: id,
      });
    } else {
      setParentandAssetId();
    }
    setAddToCollectionModal(true);
    mutationGetCollectionList.mutate();
  };

  const mutationGetUserCart = useMutation((post) => getUserCart(post), {
    onSuccess: (result) => {
      if (result?.data?.success) {
        setUserCartListingZusNon(result?.data?.data?.entries);
        setUserCartListingTotalCountZusNon(result?.data?.data?.totalResults)
      } else {
      }
    },
    onError: (error) => {},
  });

  const mutationAddToCart = useMutation((post) => addToCart(post), {
    onSuccess: (result) => {
      if (result?.data?.success) {
        showSuccessToast(result.data?.message);
        handleCloseModal();
        mutationGetUserCart.mutate();
      } else {
        showErrorToast(result.data?.message);
      }
    },
    onError: (error) => {},
  });

  const handleOpenCartOptions = (obj) => {
    setAddToCartModal(true);
    if (obj) {
      setAsset(obj);
    }
  };

  const handleDownloadModal = (id, path, name) => {
    setDownloadCustomModal(true);
    if (id) {
      setAsset(id);
    }
    // window.open(
    //   `https://dam-api.pimcoredemos.com/api/download-asset/${id}`,"_self"

    // );
    // setTimeout(() => {
    //   showSuccessToast("Asset downloaded succesfully");
    // }, 2000);

    // setDownloadModal(true);
  };
  const handleMoveModal = (id) => {
    if (id) {
      setAssetId(id);
    } else {
      setAssetId();
    }
    setIsMoveModal(true);
  };
  const handleEditBatch = () => {
    setBulkEditModal(true);
    getCollectionModalTagsMutation.mutate();
  };

  const handleCloseModal = () => {
    setAddToCollectionModal(false);
    setAddToCartModal(false);
    setDownloadModal(false);
    setIsMoveModal(false);
    setIsDeleteModal(false);
    setBulkEditModal(false);
    setDownloadCustomModal(false);
    setSelectedValues(null)
    setNameError(false)
    setCreateNewFilterNameModal(false)
    setSaveAsNewFilterNameModal(false)
    setDeleteFilterChip(false)
    setSavePopUpInputBoxVAlue("")
 
   
    setClickedMoveToFolderPopUpZusNon( {
      label: "",
      id: "",
      permissions: {},
    },)
  };

  const handleGridView = () => {
    setIsGridView(true);

    //setIsListView(false);
  };
  const handleListView = () => {
    setIsGridView(false);
    // setIsListView(true);
  };
  
  const handleNavigationEdit = (id,mode) => {
    
    navigate({
      pathname: "/detailPage",
      search: `id=${id}&mode=${mode}`,
    });
  };


  const handleNavigation = (id) => {
    // const url = `/detailPage?id=${id}`;
    // const newTab = window.open(url, '_blank');
    // if (newTab) newTab.focus();
    navigate({
      pathname: "/detailPage",
      search: `id=${id}`,
    });
  };

  function getIdsAndParents(objects) {
    return objects
      .filter((obj) => obj.isChecked === true)
      .map(({ id, parent }) => ({ id, parent }));
  }

  // getIdsAndParents(data);

  const handleShareNavigation = (param) => {
    if (param) {
      navigate(
        "/shareAssetPage",

        { state: param }
      );
    } else {
      const idParentIdPairs = getIdsAndParents(mainPageListingArr);

      const queryString = idParentIdPairs
        ?.map((pair) => `id=${pair.id}-parentId=${pair.parent}`)
        .join("&");

      navigate({
        pathname: "/shareAssetPage",
        search: `?${queryString}`,
      });
    }

    setAnchorEl(null);
  };

  const handleDeleteAsset = (obj) => {
    setIsDeleteModal(true);
    setDeleteId(obj?.id);
    // mutationDeleteAsset.mutate(id)
    // console.info("You clicked the delete icon.");
  };

  const handleChecked = (e, idx) => {
    
    if (!e.target.checked) {
      setIsAllChecked(false);
    }

    let imgCollection = mainPageListingArr?.map((item) => {
      if (item?.id === idx) {
        item.isChecked = !item.isChecked;
      }
      return item;
    });

    const countIsCheckedTrue = imgCollection?.reduce(
      (acc, obj) => (obj?.isChecked ? acc + 1 : acc),
      0
    );

    if (imgCollection?.length == countIsCheckedTrue) {
      setIsAllChecked(true);
    }
    if (imgCollection?.length > 1) {
      setOptionBox(true);
    } else {
      setOptionBox(false);
    }
    setMainPageListingArr(imgCollection);
  };

  const handleCheckAll = () => {
    let flag = !isAllChecked;
    setIsAllChecked(flag);

    let imgCollection = mainPageListingArr?.map((item) => {
      item.isChecked = flag;
      return item;
    });
    setOptionBox(true);
    setMainPageListingArr(imgCollection);
  };


  const handleCallbackDelete = () => {
    if (isDeleteModal) {
      if (deleteId) {
        let payload = {
          elementId: [deleteId],
        };
        mutationDeleteAsset.mutate(payload);
      } else {
        let payload = {
          // elementId: getSelectedIds(mainPageListingArr),

           elementId:  selectedIds
        };
        mutationDeleteAsset.mutate(payload);
      }
    }
  };
  const handleMoveTofolderCallbackApi = () => {
    let payload = {
      // folderName:clickedFolderZusNon.label,
      mimetype: selectedMimeTypeZusNon?.mimetype,
      searchTerm: location?.pathname?.includes("/searchResultsPage")
      ? searchedItem :internalSearchValue,
      folderId:location?.pathname?.includes("/searchResultsPage")? "" : clickedFolderZusNon?.id
    };
    if(!location?.pathname?.includes("/searchResultsPage"))
    {
      payload ={...payloadForSortZusNon,...payload}
    }
    mutation.mutate(payload);
  };
  const handleCallbackAddToCollection = (selectedValues) => {
    // if (parentandAssetId?.assetId && selectedValues?.value ) {
    setSelectedDropdownValue(selectedValues?.value);
    let payload = getParentandAssetId();

    mutationAddToCollection.mutate(JSON.stringify(payload));
    // }
  };

  const handleCallbackCreateNewFilterNameModal = (value) => {
  

    let payload = {
      filter: [
        {
          assetType: filterMasalaZusNon?.selectedValueType,
          mimeType:
            filterMasalaZusNon?.selectedValueTypeMime,

          creationDate:
            
            (filterMasalaZusNon?.selectedCreationDate &&
              CustomMoment(filterMasalaZusNon?.selectedCreationDate)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD")),

          modificationDate:
           
            (filterMasalaZusNon?.selectedModiDate &&
              CustomMoment(filterMasalaZusNon?.selectedModiDate)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD")),

          filterName: value,
        },
      ],
    };
    saveFilterApiMutation.mutate(payload)
 
   
  };

  

  const mutationBatchMetadataUpdate = useMutation(
    (post) => batchMetaDataUpdate(post),
    {
      onSuccess: (result) => {
        if (!result?.data?.success) {
          showErrorToast(result?.data?.data);
        } else {
          handleCloseModal();
        }
      },
      onError: (err) => {},
    }
  );
  const mutationBatchUpdate = useMutation((post) => batchUpdate(post), {
    onSuccess: (result) => {
      if (!result?.data?.success) {
        showErrorToast(result?.data?.message);
      } else {
        showSuccessToast(result?.data?.message);
        handleCloseModal();
      }
    },
    onError: (err) => {},
  });

  const handleCallbackBulkEdit = (metadataValue, selectedtags) => {
    const selectedItems = mainPageListingArr?.filter(
      (obj) => obj?.isChecked == true
    );
    const metadataPayload = metadataValue?.map((val) => {
      delete val?.idx;
      return val;
    });
    metadataPayload?.forEach((obj) => {
      // Check if the type is "date"
      if (obj.type === "date") {
        // Modify the data key using Moment.js to remove the time part
        if (obj?.data) {
          obj.data = CustomMoment(obj?.data)
            .tz("Asia/Kolkata")
            .format("YYYY-MM-DD");
        }
      }
    });

    let selectedIds = [];
    selectedItems.forEach((element) => {
      selectedIds.push(element?.id);
    });
    const payload = {
      assetIds: selectedIds?.toString(),
      metadata: JSON.stringify({ values: metadataPayload }),
      content: JSON.stringify({ tags: selectedtags }),
    };

    mutationBatchUpdate.mutate(payload);
    // mutationBatchMetadataUpdate.mutate(payload);
  };

 
useEffect(() => {
  setCurrentPage(1)

 
}, [location?.state?.searchTerm])

  useEffect(() => {
    !location?.state?.searchTerm && setSearchValueZusNon("");
      let payload = {
        searchTerm: location?.state ? location?.state?.searchTerm:"",
        currentPage: currentPage,
        folderName:!location?.state ? clickedFolderZusNon?.label: "",
        folderId: location?.pathname?.includes("/searchResultsPage")? "":(!location?.state ?  clickedFolderZusNon?.id :""),
        // folderName: clickedFolderZusNon?.label,
        sortBy: sortingZusNon && sortingZusNon[0],
        sortOrder: sortingZusNon && sortingZusNon[1],
        startDate: location?.pathname?.includes("/searchResultsPage")? "":  (filterMasalaZusNon?.selectedModiDate &&
        CustomMoment(filterMasalaZusNon?.selectedModiDate)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")),
      endDate: location?.pathname?.includes("/searchResultsPage")? "":   (filterMasalaZusNon?.selectedCreationDate &&
        CustomMoment(filterMasalaZusNon?.selectedCreationDate)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")),
      assetType: location?.pathname?.includes("/searchResultsPage")? "" :filterMasalaZusNon?.selectedValueType?.value,
      mimeType: location?.pathname?.includes("/searchResultsPage")? "": filterMasalaZusNon?.selectedValueTypeMime?.value,
      };
  
      mutation.mutate(payload);
      return () => {
        // setClickedFolderZus("")
        setFiltersZustand(null);
      };

    
  
  }, []);

  const getParentandAssetId = () => {
    let res = [];
    if (parentandAssetId?.parentId && parentandAssetId?.assetId) {
      res.push(parentandAssetId);
    } else {
      res = mainPageListingArr?.reduce((acc, curr) => {
        if (curr?.isChecked) {
          acc.push({ parentId: curr?.parent, assetId: curr?.id });
        }
        return acc;
      }, []);
    }
    return res;
  };

  const handleCrossOverlay = () => {
    setOptionBox(false);
    let imgCollection = mainPageListingArr?.map((item) => {
      item.isChecked = false;
      return item;
    });
    setIsAllChecked(false);
  };

  const handleKeyPressCallback = (e,searchValue) => {
    if (e.key === "Enter") {

        setInternalSearchValue(searchValue)
        let payload = {
          searchTerm: searchValue,
          folderName:clickedFolderZusNon?.label,
          folderId:  location?.pathname?.includes("/searchResultsPage")? "" : clickedFolderZusNon?.id ,
          sortBy: sortingZusNon && sortingZusNon[0],
          sortOrder: sortingZusNon && sortingZusNon[1],      
        };
        payload ={...payloadForSortZusNon,...payload}
        mutation.mutate(payload);
        setCurrentPage(1);
        }
  };

  const handleOnChangeCollection = (selectedOptions) => {
    setSelectedValues(selectedOptions);
  };

  useEffect(() => {
    if (
      filterMasalaZusNon === null ||
      (typeof filterMasalaZusNon === "object" &&
        Object.keys(filterMasalaZusNon).length === 0)
    ) {
      setSelectedFilterZusNon(null);

    }
  }, [filterMasalaZusNon]);
  const compareObjects =  (obj1, obj2) => {
    if (!obj1 || !obj2) {
      return false;
  }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Compare each property individually
    if (obj1.assetType.value !== obj2.selectedValueType.value) {
        return false;
    }
    if (obj1.mimeType.value !== obj2.selectedValueTypeMime.value) {
        return false;
    }
    if (obj1.creationDate !== obj2.selectedCreationDate) {
        return false;
    }
    if (obj1.modificationDate !== obj2.selectedModiDate) {
        return false;
    }

    // If all properties match, return true
    return true;
}

  // const compareObjects = (obj1,obj2) => {
  //    // Convert objects to JSON strings and compare
  //    console.log("iuacjascascyyysbchsbcs",JSON.stringify(obj1));
  //    console.log("iuacjascascyyysbchsbcs2nd",JSON.stringify(obj2));
  //    return JSON.stringify(obj1) === JSON.stringify(obj2);
   
  // };


  useEffect(() => {
    if (
      selectedFilterZusNon &&
      typeof selectedFilterZusNon === "object" &&
      Object.keys(selectedFilterZusNon)?.length > 0
    ) {
      const selectedObject =
        totalGetFilterData &&
        totalGetFilterData?.find(
          (item) => item?.filterName?.value === selectedFilterZusNon?.value
        );
    
      const configObject = selectedObject?.config[0];
      const isEqual = compareObjects(configObject, filterMasalaZusNon);
     
      setObjectIsEqual(isEqual)

    }
  }, [selectedFilterZusNon,filterMasalaZusNon]);

  const deleteFilterMutation = useMutation(
    (post) => deleteSavedFiltersApi(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            setCurrentPage(1)
            getAllFiltersApimutation.mutate();
            showSuccessToast(result?.data?.message);
            handleClearCompleteFilter();
            handleCloseModal();
          } else {
            showErrorToast(result?.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const handleDeleteFilter = () => {
    setDeleteFilterChip(true)
    
  };

  const handleCallbackDeleteFilterChip = () => {
  
    let payload = {
      filterId:selectedFilterZusNon && selectedFilterZusNon?.value,
    };
  
     deleteFilterMutation.mutate(payload);
  };

  

useEffect(() => {
  const isEmptyValue = (value) => {
    // Define what you consider as empty
    if (value === null || value === undefined) return true;
    if (typeof value === "string" && value.trim() === "") return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (
      typeof value === "object" &&
      value !== null &&
      Object.keys(value).length === 0
    )
      return true;
    return false;
  };

  const checkIfAllValuesEmpty = (obj) => {
    if (obj !== null && typeof obj === "object" && !Array.isArray(obj)) {
      return Object.entries(obj).every(([key, value]) => {
        // Define what you consider as empty
        if (value === null || value === undefined) return true;
        if (typeof value === "string" && value?.trim() === "") return true;
        if (Array.isArray(value) && value?.length === 0) return true;
        if (
          typeof value === "object" &&
          value !== null &&
          Object?.keys(value)?.length === 0
        )
          return true;
        return false;
      });
    } else {
      return false; // Return false if obj is not a valid object
    }
  };

  const checkiFallFilterValuesEmpty = checkIfAllValuesEmpty(filterMasalaZusNon);
  setAllFilterValuesEmpty(checkiFallFilterValuesEmpty);
  if (allFilterValuesEmpty) {
    handleClearCompleteFilter();
  }
}, [filterMasalaZusNon, allFilterValuesEmpty]);

  
 

  return (
    <>
      {mutation.isLoading && <Loader></Loader>}
      {mutationAddToCollection.isLoading && <Loader></Loader>}
      {mutationDeleteAsset.isLoading && <Loader></Loader>}
      {customDownloadApiMutation.isLoading && <Loader></Loader>}
      {getCollectionModalTagsMutation.isLoading && <Loader></Loader>}
      {deleteFilterMutation.isLoading && <Loader></Loader>}

      <>
        <Box>
          <DamPortalModal
            open={deleteFilterChip}
            onClose={handleCloseModal}
            titleMessage={"Delete filter"}
            deleteFilterChip={deleteFilterChip}
            handleCallbackDeleteFilterChip={handleCallbackDeleteFilterChip}
          />
        </Box>
        <Box>
          <DamPortalModal
            open={createNewFilterNameModal}
            savePopUpInputBoxVAlue={savePopUpInputBoxVAlue}
            setSavePopUpInputBoxVAlue={setSavePopUpInputBoxVAlue}
            onClose={handleCloseModal}
            titleMessage={"Save new filter"}
            saveFilterApiMutation={saveFilterApiMutation}
            createNewFilterNameModal={createNewFilterNameModal}
            handleCallbackCreateNewFilterNameModal={
              handleCallbackCreateNewFilterNameModal
            }
          />
        </Box>
        <Box>
          <DamPortalModal
            open={saveAsNewFilterNameModal}
            savePopUpInputBoxVAlue={savePopUpInputBoxVAlue}
            setSavePopUpInputBoxVAlue={setSavePopUpInputBoxVAlue}
            onClose={handleCloseModal}
            titleMessage={"Save as new filter"}
            saveFilterApiMutation={saveFilterApiMutation}
            saveAsNewFilterNameModal={saveAsNewFilterNameModal}
            handleCallbackCreateNewFilterNameModal={
              handleCallbackCreateNewFilterNameModal
            }
          />
        </Box>

        <Box>
          <DamPortalModal
            open={addToCollectionModal}
            onClose={handleCloseModal}
            titleMessage={commonMessages?.addToCollection}
            addToCollectionModalFlag={addToCollectionModal}
            mutationAddToCollection={mutationAddToCollection}
            collectionListZusNon={collectionListZusNon}
            parentandAssetId={getParentandAssetId}
            selectedValues={selectedValues}
            handleOnChangeCollection={handleOnChangeCollection}
            handleCallbackAddToCollection={handleCallbackAddToCollection}
          />
        </Box>
        <Box>
          <DamPortalModal
            open={addToCartModal}
            addToCartModalFlag={addToCartModal}
            mutationAddToCart={mutationAddToCart}
            onClose={handleCloseModal}
            titleMessage={commonMessages?.addToCart}
            handleCallbackAddToCart={handleCallbackAddToCart}
          />
        </Box>
        <Box>
          <DamPortalModal
            open={downloadCustomModal}
            downloadCustomModalFlag={downloadCustomModal}
            onClose={handleCloseModal}
            titleMessage={"Download"}
            customDownloadApiMutation={customDownloadApiMutation}
            handleCallbackDownload={handleCallbackDownload}
          />
        </Box>
        <Box>
          <DamPortalModal
            open={downloadModal}
            onClose={handleCloseModal}
            titleMessage={"Download"}
          />
        </Box>
        {/* <Box>
          <DamPortalModal
              openDrawer={IsMoveModal}
              onClose={handleCloseModal}
              setIsMoveModal={setIsMoveModal}
              titleMessage={"Move to folder"}
              // node={selectedNode}
              moveTofolderModalFlag={IsMoveModal}
              assetId={assetId ? [assetId] : getSelectedIds(mainPageListingArr)}
              assetArr={mainPageListingArr}
              handleMoveTofolderCallbackApi={handleMoveTofolderCallbackApi}
            />
        </Box> */}

        <Drawer open={IsMoveModal} anchor="right" className="uploadAsset">
          <Typography component="h5" variant="h5" className="drawertitle">
            <Move height={25} />{" "}
            <span className="marginL-xs">Move to folder</span>
            <Close className="closed" onClick={() => handleCloseModal()} />
          </Typography>
          <Box className="">
            <Grid container>
              <Grid item xs={12} className="manageFolder">
                <FolderTreeMoveToFolder
                  assetId={
                    // assetId ? [assetId] : getSelectedIds(mainPageListingArr)
                    assetId ? [assetId] : selectedIds
                  }
                  setIsMoveModal={setIsMoveModal}
                  setIsAllChecked={setIsAllChecked}
                  moveTofolderModalFlag={IsMoveModal}
                  hanldleCallbackMoveToFolder={hanldleCallbackMoveToFolder}
                  handleMoveTofolderCallbackApi={handleMoveTofolderCallbackApi}
                  assetArr={mainPageListingArr}
                />

                {/* <FolderTree
                  assetId={
                    assetId ? [assetId] : getSelectedIds(mainPageListingArr)
                  }
                  setIsMoveModal={setIsMoveModal}
                  moveTofolderModalFlag={IsMoveModal}
                  hanldleCallbackMoveToFolder={hanldleCallbackMoveToFolder}
                  handleMoveTofolderCallbackApi={handleMoveTofolderCallbackApi}
                  assetArr={mainPageListingArr}
                /> */}
              </Grid>
            </Grid>
          </Box>
        </Drawer>

        <Box>
          <DamPortalModal
            open={bulkEditModal}
            onClose={handleCloseModal}
            titleMessage={commonMessages?.batchMetdataUpdate}
            nameError={nameError}
            uploadFileTags={bulkEditTags}
            setNameError={setNameError}
            bulkEditModal={bulkEditModal}
            handleCallbackBulkEdit={handleCallbackBulkEdit}
            mutationBatchUpdate={mutationBatchUpdate}
          />
        </Box>
        <Box>
          <DamPortalModal
            open={isDeleteModal}
            onClose={handleCloseModal}
            titleMessage={"Delete"}
            deleteAssetFromLandingPage={isDeleteModal}
            deleteMultiple={selectedCount > 1 ? true : false}
            handleCallbackDelete={handleCallbackDelete}
          />
        </Box>

        <Box className="rightSection">
          <Box className="lastMenu">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {location?.pathname !== "/searchResultsPage" && (
                <SortMenu
                  handleKeyPressCallback={handleKeyPressCallback}
                  headerFilterList={headerFilterList}
                  setselectedModiDate={setselectedModiDate}
                  selectedModiDate={selectedModiDate}
                  selectedCreationDate={selectedCreationDate}
                  setselectedCreationDate={setselectedCreationDate}
                  selectedValueType={selectedValueType}
                  setSelectedValueType={setSelectedValueType}
                  selectedValueTypeMime={selectedValueTypeMime}
                  setSelectedValueTypeMime={setSelectedValueTypeMime}
                  savedFilterDropdownSelectedLocal={
                    savedFilterDropdownSelectedLocal
                  }
                  setSavedFilterDropdownSelectedLocal={
                    setSavedFilterDropdownSelectedLocal
                  }
                  totalGetFilterData={totalGetFilterData}
                  setTotalGetFilterData={setTotalGetFilterData}
                  filterLabelValue={filterLabelValue}
                  setFilterLabelValue={setFilterLabelValue}
                  handleClearCompleteFilter={handleClearCompleteFilter}
                   setLoader={setLoader}
                  setInternalSearchValue={setInternalSearchValue}
                  internalSearchValue={internalSearchValue}
                  payloadForSortZusNon={payloadForSortZusNon}
                  setIsAllChecked={setIsAllChecked}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
              <Button
                onClick={handleClickSort}
                size="small"
                aria-controls={openSort ? "sorting" : undefined}
                aria-haspopup="true"
                aria-expanded={openSort ? "true" : undefined}
                startIcon={<Sort />}
                endIcon={<DownArrow />}
                // className={`menuButton ${item.menuId===clickedMenu ? "active":""}`}
                className="menuButton"
                disabled={totalCountZustand == 0}
              >
                Sort
              </Button>
              <Menu
                anchorEl={anchorElSort}
                id="sorting"
                open={openSort}
                onClose={handleCloseSort}
                //  onClick={handleCloseSort}
                {...paperPropsStyles}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                // anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              >
                {sortArray?.map((sortObj) => (
                  <MenuItem
                    sx={{
                      color:
                        sortObj?.value === sortingZusNon.join(" ")
                          ? "var(--primary)"
                          : "",
                    }}
                    onClick={() => handleCloseSort(sortObj)}
                  >
                    {sortObj?.label}
                    <Box
                      component="span"
                      sx={{ display: "inline", marginLeft: "3px" }}
                    >
                      {sortObj?.svg}
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
              <Box className={`checkbox ${isAllChecked ? "checked" : ""}`}>
                <Checkbox
                  checked={isAllChecked}
                  name="select all"
                  onClick={handleCheckAll}
                  disabled={totalCountZustand == 0}
                />
                <Typography component="label">Select all</Typography>
              </Box>
            </Box>

            <Box sx={{ textAlign: "right" }}>
              <TooltipStyle placement="bottom" arrow title="Grid view">
                <Button
                  sx={{
                    cursor: "pointer",
                    marginRight: "2px !important",
                  }}
                  onClick={handleGridView}
                  size="small"
                  className={`button icon ${gridView ? "active" : ""}`}
                >
                  <CardView />
                </Button>
              </TooltipStyle>
              <TooltipStyle placement="bottom" arrow title="List view">
                <Button
                  size="small"
                  sx={{ cursor: "pointer" }}
                  onClick={handleListView}
                  className={`button icon ${!gridView ? "active" : ""}`}
                >
                  <ListView />
                </Button>
              </TooltipStyle>
            </Box>
          </Box>

          {location?.pathname == "/searchResultsPage" ? (
            <Box className="selector ">
              <Box className="breadcrumb">
                <Typography component="h2" variant="h5" className="heading">
                  Search results for <span>{location?.state?.searchTerm}</span>
                </Typography>
              </Box>
            </Box>
          ) : (
            ""
          )}
          {internalSearchValue ? (
            <Box className="filterResultWrapper">
              <Typography component="h2" variant="h5" className="heading">
                Search results for <span>{internalSearchValue}</span>
              </Typography>
            </Box>
          ) : (
            ""
          )}

          <Box className="flex filterResultWrapper">
            {!selectedFilterZusNon ||
            selectedFilterZusNon === null ||
            (selectedFilterZusNon &&
              typeof selectedFilterZusNon === "object" &&
              Object.keys(selectedFilterZusNon)?.length === 0) ? (
              <>{}</>
            ) : (
              <Box className="filterResult mR">
                Applied: <b>{selectedFilterZusNon?.label}</b>
              </Box>
            )}
            {typeof filterMasalaZusNon?.selectedModiDate === "object" ? (
              <>{formatModifiedObjectDate()}</>
            ) : filterMasalaZusNon?.selectedModiDate ? (
              <Box className="filterResult">
                <Chip
                  label={` From : ${filterMasalaZusNon?.selectedModiDate}`}
                  deleteIcon={<Closed height={15} />}
                  onDelete={handleClearSelectedModiDate}
                />
              </Box>
            ) : null}
            {typeof filterMasalaZusNon?.selectedCreationDate === "object" ? (
              <> {formatCreationObjectDate()}</>
            ) : filterMasalaZusNon?.selectedCreationDate ? (
              <Box className="filterResult">
                <Chip
                  label={`To: ${filterMasalaZusNon?.selectedCreationDate}`}
                  deleteIcon={<Closed height={15} />}
                  onDelete={handleClearSelectedCreationDate}
                />
              </Box>
            ) : null}
            {filterMasalaZusNon?.selectedValueType?.label ? (
              <Box className="filterResult">
                <Chip
                  label={filterMasalaZusNon?.selectedValueType?.label}
                  deleteIcon={<Closed height={15} />}
                  onDelete={(e)=>handleClearSelectedValueType(e)}
                />
              </Box>
            ) : null}{" "}
            {filterMasalaZusNon?.selectedValueTypeMime?.label ? (
              <Box className="filterResult">
                <Chip
                  label={filterMasalaZusNon?.selectedValueTypeMime?.label}
                  deleteIcon={<Closed height={15} />}
                  onDelete={handleClearSelectedValueTypeMime}
                />
              </Box>
            ) : null}
            {/* {Object?.entries(filterMasalaZusNon)?.length > 0 ? ( */}
            {filterMasalaZusNon &&
            typeof filterMasalaZusNon === "object" &&
            Object?.entries(filterMasalaZusNon)?.length > 0 ? (
              <Box component="span" sx={{}}>
                <Box className="filterResult">
                  <TooltipStyle placement="bottom" arrow title="Remove filter">
                    <Button
                      className="button icon"
                      onClick={handleClearCompleteFilter}
                    >
                      {" "}
                      <RemoveFilter />
                    </Button>
                    {/* <Chip
                    
                    deleteIcon={<Closed height={15} />}
                    onDelete={handleClearCompleteFilter}
                  /> */}
                  </TooltipStyle>
                </Box>
              </Box>
            ) : null}
            {(selectedFilterZusNon === null ||
              (selectedFilterZusNon &&
                typeof selectedFilterZusNon === "object" &&
                Object.keys(selectedFilterZusNon)?.length === 0)) &&
            filterMasalaZusNon &&
            typeof filterMasalaZusNon === "object" &&
            Object?.entries(filterMasalaZusNon)?.length > 0 ? (
              <>
                <TooltipStyle placement="bottom" arrow title="Save filter">
                  <Button
                    variant="outlined"
                    onClick={handleCreateNewFilter}
                    className="button icon"
                  >
                    <SaveFilter />
                  </Button>
                </TooltipStyle>
              </>
            ) : (
              <></>
            )}
            {selectedFilterZusNon &&
            typeof selectedFilterZusNon === "object" &&
            Object.keys(selectedFilterZusNon)?.length > 0 ? (
              <>
                {" "}
                <TooltipStyle
                  placement="bottom"
                  arrow
                  title="Save as new filter"
                >
                  {!objectIsEqual && (
                    <Button
                      variant="outlined"
                      onClick={handleSaveAsNewFilter}
                      className="button icon"
                    >
                      <SaveAsNew />
                    </Button>
                  )}
                </TooltipStyle>
                {/* save as new filter tabhi aega jab poorane saved filter me kuch change kre hai user ne */}
                {/* update filter ni aega  */}
                {/* saved filter me jo dropdown option aa rhe hain sabke saaath ek delete button aega to delete that filter */}
                <TooltipStyle placement="bottom" arrow title="Delete filter">
                  <Button
                    variant="outlined"
                    onClick={handleDeleteFilter}
                    className="button icon"
                  >
                    <DeleteFilter />
                  </Button>
                </TooltipStyle>
              </>
            ) : (
              <></>
            )}
          </Box>
          {!mutation?.isLoading && !loader && !loaderZusNon ? (
            totalCountZustand > 0 ? (
              <>
                <Box className="views">
                  {gridView ? (
                    <Box sx={{ minHeight: "75vh" }}>
                      <Box className="box">
                        {mainPageListingArr?.map((imgObj) => (
                          <>
                            <div className="item" key={imgObj?.id}>
                              <Card
                                className={`card ${
                                  isAllChecked || imgObj?.isChecked
                                    ? "checked"
                                    : ""
                                }`}
                                data-open={`${
                                  menuOpenItem === imgObj?.id
                                    ? "clickedB"
                                    : "no"
                                }`}
                              >
                                <Box className="cardMedia">
                                  {imgObj?.mimetype?.length === 0 ? (
                                    <>
                                      <Noimage
                                        height={114}
                                        onClick={() => {
                                          handleNavigation(imgObj?.id);
                                        }}
                                      />
                                    </>
                                  ) : (
                                    // imgObj?.mimetype == "application/pdf" ? (
                                    //   <>
                                    //     {" "}
                                    //     <PdfIcon
                                    //       onClick={() => {
                                    //         handleNavigation(imgObj?.id);
                                    //       }}
                                    //       height={114}
                                    //     />
                                    //   </>
                                    // ) : imgObj?.mimetype == "text/html" ? (
                                    //   <>
                                    //     {" "}
                                    //     <HtmlIcon
                                    //       onClick={() => {
                                    //         handleNavigation(imgObj?.id);
                                    //       }}
                                    //       height={114}
                                    //     />
                                    //   </>
                                    // ) : imgObj?.mimetype == "text/csv" ? (
                                    //   <>
                                    //     {" "}
                                    //     <CsvIcon
                                    //       onClick={() => {
                                    //         handleNavigation(imgObj?.id);
                                    //       }}
                                    //       height={114}
                                    //     />
                                    //   </>
                                    // ) : imgObj?.mimetype == "video/mp4" ? (
                                    //   <>
                                    //     {" "}
                                    //     <Mp4Icon
                                    //       onClick={() => {
                                    //         handleNavigation(imgObj?.id);
                                    //       }}
                                    //       height={114}
                                    //     />
                                    //   </>
                                    // ) : imgObj?.mimetype ==
                                    //   "application/postscript" ? (
                                    //   <>
                                    //     {" "}
                                    //     <EpsIcon
                                    //       onClick={() => {
                                    //         handleNavigation(imgObj?.id);
                                    //       }}
                                    //       height={114}
                                    //     />
                                    //   </>
                                    // ) : imgObj?.mimetype == "image/tiff" ? (
                                    //   <>
                                    //     {" "}
                                    //     <TiffIcon
                                    //       onClick={() => {
                                    //         handleNavigation(imgObj?.id);
                                    //       }}
                                    //       height={114}
                                    //     />
                                    //   </>
                                    // ) : imgObj?.mimetype == "image/svg+xml" ? (
                                    //   <>
                                    //     {" "}
                                    //     <SvgIcon
                                    //       onClick={() => {
                                    //         handleNavigation(imgObj?.id);
                                    //       }}
                                    //       height={114}
                                    //     />
                                    //   </>
                                    // ) : imgObj?.mimetype == "image/x-photoshop" ? (
                                    //   <>
                                    //     {" "}
                                    //     <PsdIcon
                                    //       onClick={() => {
                                    //         handleNavigation(imgObj?.id);
                                    //       }}
                                    //       height={114}
                                    //     />
                                    //   </>
                                    // ) : imgObj?.mimetype ==
                                    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                    //   <>
                                    //     {" "}
                                    //     <Noimage
                                    //       onClick={() => {
                                    //         handleNavigation(imgObj?.id);
                                    //       }}
                                    //       height={114}
                                    //     />
                                    //   </>
                                    // ) :

                                    <CardMedia
                                    
                                      sx={{
                                        height: 120,
                                        backgroundSize: "contain",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleNavigation(imgObj?.id);
                                      }}
                                      image={`${"https://"}${"dam-api.pimcoredemos.com"}${
                                        imgObj?.thumbnail
                                      }`}
                                      // image={`${"http://"}${"dam-api.pimcoredemos.com"}${
                                      //   imgObj?.path
                                      // }${imgObj?.name}`}
                                      // image={imgObj?.path}
                                      // title={imgObj?.displayName}
                                    />
                                  )}
                                </Box>
                                <CardContent
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box sx={{ width: "80%", display: "flex" }}>
                                    <Checkbox
                                      checked={imgObj?.isChecked}
                                      // value={imgObj?.isChecked}
                                      id={imgObj?.id}
                                      onClick={(e) =>
                                        handleChecked(e, imgObj?.id)
                                      }
                                    />
                                    <TooltipStyle
                                      placement="bottom"
                                      arrow
                                      title={
                                        imgObj?.displayName
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                        imgObj?.displayName?.slice(1)
                                      }
                                    >
                                      <label htmlFor={imgObj?.id}>
                                        {truncate(
                                          imgObj?.displayName
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                            imgObj?.displayName?.slice(1),
                                          35
                                        )}
                                      </label>
                                    </TooltipStyle>
                                  </Box>
                                  <Button
                                    onClick={(e) =>
                                      handleClickOption(e, imgObj)
                                    }
                                    size="small"
                                    sx={{ marginRight: "5px !important" }}
                                    aria-controls={
                                      openOption ? "Option" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      openOption ? "true" : undefined
                                    }
                                    className={`button icon ${
                                      menuOpenItem === imgObj?.id
                                        ? "clickedB"
                                        : "no"
                                    }`}
                                  >
                                    <More />
                                  </Button>

                                  <Menu
                                    anchorEl={anchorElOption}
                                    id="Option"
                                    open={openOption}
                                    disableScrollLock={true}
                                    onClose={handleCloseOption}
                                    onClick={handleCloseOption}
                                    // {...paperPropsStyles}
                                    {...paperPropsStylesThreeDotsLAndingPAge}
                                    transformOrigin={{
                                      horizontal: "right",
                                      vertical: "bottom",
                                    }}
                                    anchorOrigin={{
                                      horizontal: "right",
                                      vertical: "top",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() =>
                                        handleAddToCollection(
                                          loopMenuPopId?.id,
                                          loopMenuPopId?.parent
                                        )
                                      }
                                    >
                                      <Media />{" "}
                                      {commonMessages?.addToCollection}
                                    </MenuItem>

                                    {clickedFolderZusNon?.permissions
                                      ?.download_asset && (
                                      <MenuItem
                                        onClick={() =>
                                          handleOpenCartOptions(loopMenuPopId)
                                        }
                                      >
                                        <Shop /> {commonMessages?.addToCart}
                                      </MenuItem>
                                    )}
                                    {clickedFolderZusNon?.permissions
                                      ?.move_asset && (
                                      <MenuItem
                                        onClick={() =>
                                          handleMoveModal(loopMenuPopId?.id)
                                        }
                                      >
                                        <Move /> Move to folder
                                      </MenuItem>
                                    )}
                                    {clickedFolderZusNon?.permissions
                                      ?.download_asset && (
                                      <MenuItem
                                        onClick={() =>
                                          handleDownloadModal(
                                            loopMenuPopId?.id,
                                            loopMenuPopId?.path,
                                            loopMenuPopId?.name
                                          )
                                        }
                                      >
                                        <Download /> Download
                                      </MenuItem>
                                    )}

                                    <MenuItem
                                      onClick={() => {
                                        handleShareNavigation(loopMenuPopId);
                                      }}
                                    >
                                      <Share /> Share
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                          handleNavigationEdit(loopMenuPopId?.id,"edit");
                                        }}
                                  
                                    >
                                      <Share /> Edit
                                    </MenuItem>
                                    {clickedFolderZusNon?.permissions
                                      ?.delete_asset && (
                                      <MenuItem
                                        onClick={() => {
                                          handleDeleteAsset(loopMenuPopId);
                                        }}
                                      >
                                        <Delete /> Delete
                                      </MenuItem>
                                    )}
                                  </Menu>

                                  {/* <label
                            className="labelCard"
                              onClick={() => {
                                handleNavigation(imgObj?.id);
                              }}
                            > */}

                                  {/* {imgObj?.name.toUpperCase(0)} */}
                                  {/* </label> */}

                                  {/* <Radio /> */}
                                </CardContent>
                              </Card>
                            </div>
                          </>
                        ))}
                        {/* <Grid item xs={12}> */}

                        {/* <p>currentPage:{currentPage}</p> */}
                        {/* <Pagination count={5} /> */}
                        {/* </Grid> */}
                      </Box>
                      <PaginationComponent
                        currentPage={currentPage}
                        totalCount={totalCountZustand}
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                        itemCount={mainPageListingArr?.length || 0}
                        // itemsPerPage={itemsPerPage}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ minHeight: "75vh" }}>
                      <TableContainer
                        sx={{ padding: "1em", marginBottom: "30px" }}
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component="th" width="2%">
                                {" "}
                                <Checkbox
                                  checked={isAllChecked}
                                  name="select all"
                                  onClick={handleCheckAll}
                                  disabled={totalCountZustand == 0}
                                />
                              </TableCell>
                              <TableCell component="th" width="8%">
                                Image
                              </TableCell>

                              <TableCell component="th" width="40%">
                                Name
                              </TableCell>
                              <TableCell component="th" width="15%">
                                Mime type
                              </TableCell>
                              <TableCell component="th" width="15%">
                                Creation date
                              </TableCell>
                              <TableCell component="th" width="15%">
                                Modification date
                              </TableCell>
                              <TableCell component="th" width="15%">
                                Size
                              </TableCell>
                              <TableCell
                                component="th"
                                width="20%"
                                align="center"
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              "& .button": {
                                border: 0,
                                margin: "2px !important",
                              },
                              "& img": {
                                height: "60px",
                                maxWidth: "60px",
                                objectFit: "cover",
                              },
                            }}
                          >
                            {mainPageListingArr?.map((imgObj) => (
                              <TableRow
                                tabIndex={-1}
                                className={`${
                                  isAllChecked || imgObj?.isChecked
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                <TableCell>
                                  <Checkbox
                                    checked={imgObj?.isChecked}
                                    // value={imgObj?.isChecked}
                                    onClick={(e) =>
                                      handleChecked(e, imgObj?.id)
                                    }
                                  />
                                </TableCell>
                                <TableCell key={imgObj?.id}>
                                  <TooltipStyle
                                    // title={`${imgObj?.displayName}`}
                                    placement="bottom"
                                    arrow
                                  >
                                    {imgObj?.mimetype?.length === 0 ? (
                                      <>
                                        <Noimage
                                          height={70}
                                          className="svgNoImage"
                                        />
                                      </>
                                    ) : (
                                      // imgObj?.mimetype == "application/pdf" ? (
                                      //   <>
                                      //     {" "}
                                      //     <PdfIcon
                                      //       height={70}
                                      //       className="svgNoImage"
                                      //     />
                                      //   </>
                                      // ) : imgObj?.mimetype == "text/html" ? (
                                      //   <>
                                      //     {" "}
                                      //     <HtmlIcon
                                      //       height={70}
                                      //       className="svgNoImage"
                                      //     />
                                      //   </>
                                      // ) : imgObj?.mimetype == "text/csv" ? (
                                      //   <>
                                      //     {" "}
                                      //     <CsvIcon height={120} />
                                      //   </>
                                      // ) : imgObj?.mimetype == "video/mp4" ? (
                                      //   <>
                                      //     {" "}
                                      //     <Mp4Icon height={120} />
                                      //   </>
                                      // ) : imgObj?.mimetype ==
                                      //   "application/postscript" ? (
                                      //   <>
                                      //     {" "}
                                      //     <EpsIcon height={120} />
                                      //   </>
                                      // ) : imgObj?.mimetype == "image/tiff" ? (
                                      //   <>
                                      //     {" "}
                                      //     <TiffIcon height={120} />
                                      //   </>
                                      // ) : imgObj?.mimetype == "image/svg+xml" ? (
                                      //   <>
                                      //     {" "}
                                      //     <SvgIcon height={120} />
                                      //   </>
                                      // ) : imgObj?.mimetype ==
                                      //   "image/x-photoshop" ? (
                                      //   <>
                                      //     {" "}
                                      //     <PsdIcon height={120} />
                                      //   </>
                                      // ) :

                                      <img
                                        src={`${"https://"}${"dam-api.pimcoredemos.com"}${
                                          imgObj?.thumbnail
                                        }`}
                                      />
                                      // <img
                                      //   src={`${"http://"}${"dam-api.pimcoredemos.com"}${
                                      //     imgObj?.path
                                      //   }${imgObj?.name}`}
                                      // />
                                    )}
                                  </TooltipStyle>
                                  {/* <img src={imgObj?.path} title="green iguana" /> */}
                                </TableCell>
                                <TableCell
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleNavigation(imgObj?.id);
                                  }}
                                >
                                  <Typography variant="p">
                                    {imgObj?.displayName
                                      .charAt(0)
                                      .toUpperCase() +
                                      imgObj?.displayName.slice(1)}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="p">
                                    {imgObj?.mimetype}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="p">
                                    {imgObj?.creationDate}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="p">
                                    {imgObj?.modificationDate}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="p">
                                    {imgObj?.size}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Box className="buttonGroup">
                                    <TooltipStyle
                                      placement="bottom"
                                      arrow
                                      title={commonMessages?.addToCollection}
                                    >
                                      <Button
                                        onClick={() =>
                                          handleAddToCollection(
                                            imgObj?.id,
                                            imgObj?.parent
                                          )
                                        }
                                        size="small"
                                        className="button icon"
                                      >
                                        <Media />
                                      </Button>
                                    </TooltipStyle>
                                    {clickedFolderZusNon?.permissions
                                      ?.download_asset && (
                                      <TooltipStyle
                                        placement="bottom"
                                        arrow
                                        title={commonMessages?.addToCart}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleOpenCartOptions(imgObj)
                                          }
                                          size="small"
                                          className="button icon"
                                        >
                                          <Shop />
                                        </Button>
                                      </TooltipStyle>
                                    )}

                                    {clickedFolderZusNon?.permissions
                                      ?.move_asset && (
                                      <TooltipStyle
                                        placement="bottom"
                                        arrow
                                        title="Move to folder"
                                      >
                                        <Button
                                          onClick={() => handleMoveModal(imgObj?.id)}
                                          size="small"
                                          className="button icon"
                                        >
                                          <Move />
                                        </Button>
                                      </TooltipStyle>
                                    )}
                                    {clickedFolderZusNon?.permissions
                                      ?.download_asset && (
                                      <TooltipStyle
                                        placement="bottom"
                                        arrow
                                        title="Download"
                                      >
                                        <Button
                                          onClick={() =>
                                            handleDownloadModal(imgObj)
                                          }
                                          size="small"
                                          className="button icon"
                                        >
                                          <Download />
                                        </Button>
                                      </TooltipStyle>
                                    )}
                                    <TooltipStyle
                                      placement="bottom"
                                      arrow
                                      title="Share"
                                    >
                                      <Button
                                        size="small"
                                        className="button icon"
                                        onClick={() => {
                                          handleShareNavigation(imgObj);
                                        }}
                                      >
                                        <Share />
                                      </Button>
                                    </TooltipStyle>
                                    {clickedFolderZusNon?.permissions
                                      ?.delete_asset && (
                                      <TooltipStyle
                                        placement="bottom"
                                        arrow
                                        title="Delete"
                                      >
                                        <Button
                                          size="small"
                                          className="button icon"
                                          onClick={() =>
                                            handleDeleteAsset(imgObj)
                                          }
                                        >
                                          <Delete />
                                        </Button>
                                      </TooltipStyle>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <PaginationComponent
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalCount={totalCountZustand}
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                        itemCount={mainPageListingArr?.length || 0}
                        // itemsPerPage={itemsPerPage}
                      />
                    </Box>
                  )}

                  {/* {getSelectedIds(mainPageListingArr)?.length > 1 &&
                  optionBox && ( */}
                  {showSelectedBox && (
                    <Box className="selected">
                      <Box className="selectedOne">
                        <>
                          <span>{selectedCount}</span>
                          {selectedCount > 1 ? "Assets" : "Asset"} selected
                        </>
                      </Box>

                      <Button
                        className="button link"
                        onClick={() => handleAddToCollection()}
                      >
                        <Media /> {commonMessages?.addToCollection}
                      </Button>
                      {clickedFolderZusNon?.permissions?.download_asset && (
                        <Button
                          className="button link"
                          onClick={() => handleOpenCartOptions()}
                        >
                          <Shop />
                          {commonMessages?.addToCart}
                        </Button>
                      )}
                      {clickedFolderZusNon?.permissions?.move_asset && (
                        <Button
                          className="button link"
                          onClick={() => handleMoveModal()}
                        >
                          <Move />
                          Move to folder
                        </Button>
                      )}
                      {clickedFolderZusNon?.permissions?.download_asset && (
                        <Button
                          className="button link"
                          onClick={() => handleDownloadModal()}
                          // onClick={handleDownloadSelected}
                        >
                          <Download />
                          Download
                        </Button>
                      )}
                      {clickedFolderZusNon?.permissions?.edit_asset && (
                        <Button
                          className="button link"
                          onClick={() => handleEditBatch()}
                        >
                          <Edit /> Edit
                        </Button>
                      )}

                      <Button
                        className="button link"
                        onClick={() => {
                          handleShareNavigation();
                        }}
                      >
                        <Share />
                        Share
                      </Button>
                      {clickedFolderZusNon?.permissions?.delete_asset && (
                        <Button
                          className="button link"
                          onClick={handleDeleteAsset}
                        >
                          <Delete />
                          Delete
                        </Button>
                      )}
                      <Button
                        className="button link"
                        onClick={() => handleCrossOverlay()}
                      >
                        <Cross />
                        Clear all
                      </Button>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              !clickedFolderZusNon?.permissions
              ?.view_asset
              ) ? (
              <>
                <Box className="views no-bg">
                  {/* <NoFolderSelectedSvg  height={200}/> */}

                  <Box
                    sx={{
                      marginTop: "var(--sideSetHeight)",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      py={5}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img src={NoFolderSelected} />
                    </Box>

                    <Box
                      component="h2"
                      variant="h2"
                      mt={5}
                      sx={{ fontWeight: "normal" }}
                    >
                      Please select permitted folder to view asset
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="views">
                <Box className="notificationWrapper ">
                  <Box className="notification">No asset is present.</Box>
                </Box>
              </Box>
            )
          ) : (
            ""
          )}
        </Box>
      </>
    </>
  );
};

export default RightSectionImages;